import React, {
  useContext, useEffect, useRef
} from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsis, faStar, faSidebarFlip, faBringForward,
  faFileCirclePlus, faFolder, faGrid2, faTrash, faTag
} from '@fortawesome/pro-regular-svg-icons'
import { faStar as faSolidStar } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import ProductContext from '../../provider/context/product.context'
import { CHANGE_PRODUCT_PRIVATE_STATE, REPLACE_LABEL } from '../../../graphql/mutation/product'

import { ProductLabel, ViewType } from '../../../shared/enum'
import { MenuAction } from '../../enum/menu-action'
import { store } from '../../../../configureStore'
import { clearUiWhenDetailPanelCloseAction } from '../../../../redux/actions/clear.actions'
import { showProductInDetailsPanelSaga } from '../../../../redux/sagas/product.saga'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { MenuItemComponent } from '../../../shared/component/general/menu-item.component'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { normalize } from '../../../../utils/tools'
import { getSegmentTrack } from '../../../shared/util/segment'
import { TrackEventType } from '../../../shared/enum/track-events'
import FeatureContext from '../../../dashboard/context/feature.context'
import { Feature } from '../../../shared/enum/feature'
import { ProductDetailPanelContainer } from '../detail-panel/product-detail-panel.container'
import { useHandleMutationResponse } from '../../../shared/hook/use-handle-mutation-response'

const CardMenuContainerWrapper = styled.div`
  .p-button-outlined.p-button-plain {
    width: 1.125rem;
    height: 1.125rem;
    color: var(--gray-300);
    box-shadow: none !important;
  }
`

export type CardMenuContainerProps = {
  id: string,
  name: string,
  favorite?: boolean,
  isMouseOver: boolean,
  isSelected?: boolean
}
export const MenuProductContainer = ({
  id, name, favorite = false, isMouseOver, isSelected = false
}: CardMenuContainerProps) => {
  const { t } = useTranslation([ 'common', 'product' ])
  const { handleResponse } = useHandleMutationResponse(t)
  const [ trackEventInSegment ] = getSegmentTrack()

  const {
    label = ProductLabel.Product,
    updateProduct,
    refetchFavProducts = () => {},
    refetchProducts = () => {},
    selectedEntity,
    selectedAction,
    selectedViewType
  } = useContext(ProductContext)
  const { isFeatureEnabled } = useContext(FeatureContext)
  const enableProductDetailPanel = isFeatureEnabled(Feature.ProductDetailPanel) || false
  const {
    reopenSidebar,
    selectedEntity: selectedEntityDashboard,
    updateDashboard,
    openSidebar = () => {},
    closeSidebar = () => {}
  } = useContext(DashboardContext)

  useEffect(() => {
    if (reopenSidebar && selectedEntityDashboard?.id && enableProductDetailPanel) {
      openProductDetailSidebar(selectedEntityDashboard.id)
    }
  }, [ reopenSidebar ])

  const isProduct = label === ProductLabel.Product
  const menu = useRef<Menu>(null)
  const [
    replaceLabel,
    {
      error: failedReplacingLabel,
      data: replaceLabelData,
      loading: replacingLabel
    }
  ] = useMutation(REPLACE_LABEL)
  const replacedLabel = replaceLabelData?.replaceLabel

  useEffect(() => {
    if (!replacingLabel && (replacedLabel || failedReplacingLabel)) {
      handleResponse({
        error: failedReplacingLabel,
        data: replacedLabel,
        callback: reloadProductList,
        successToastDetail: { label: 'messages.success', context: 'movedObject', ns: 'product' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'movedObject', ns: 'product' }
      })
    }
  }, [ replacingLabel, replacedLabel, failedReplacingLabel ])

  // TODO : Added for compatibility with v0.9
  const state = store.getState()
  const isDetailsPanelOpen = FlagsSelector.isDetailsPanelOpen(state)
  useEffect(() => {
    if (!isDetailsPanelOpen && selectedAction === MenuAction.OpenProductDetail) {
      updateProduct({ selectedEntity: null })
    }
  }, [ isDetailsPanelOpen ])

  const [
    changeProductFavoriteState,
    {
      error: failedChangingProductState,
      data: changeProductFavoriteStateData,
      loading: changingProductState
    }
  ] = useMutation(CHANGE_PRODUCT_PRIVATE_STATE)
  const changedProduct = changeProductFavoriteStateData?.changeProductFavoriteState

  useEffect(() => {
    if (!changingProductState && (changedProduct || failedChangingProductState)) {
      handleResponse({
        error: failedChangingProductState,
        data: changedProduct,
        callback: reloadProductList,
        successToastDetail: { label: 'messages.success', context: 'favState', ns: 'product' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'favState', ns: 'product' }
      })
    }
  }, [ changingProductState, changedProduct, failedChangingProductState ])

  const reloadProductList = () => {
    refetchProducts()
    refetchFavProducts()
  }

  const executeCommand = (event: any) => {
    event.originalEvent.stopPropagation()
    const { action: selectedAction } = event.item
    const selectedEntity = { id, name }
    switch (selectedAction) {
    case MenuAction.MoveProductToFolder:
      updateProduct({ showMoveToFolderDialog: true, selectedAction, selectedEntity })
      break
    case MenuAction.MoveProductToObjects:
      trackEventInSegment(TrackEventType.MOVED_TO_OBJECT)
      updateProduct({ selectedAction })
      replaceLabel({ variables: { productID: id, labelToReplace: ProductLabel.Product, replacementLabel: ProductLabel.Object } })
      break
    case MenuAction.MoveObjectToProduct:
      trackEventInSegment(TrackEventType.MOVED_TO_PRODUCT)
      updateProduct({ selectedAction })
      replaceLabel({ variables: { productID: id, labelToReplace: ProductLabel.Object, replacementLabel: ProductLabel.Product } })
      break
    case MenuAction.CreateScenario:
      updateProduct({ showCreateScenarioDialog: true, selectedAction, selectedEntity })
      break
    case MenuAction.CompareProducts:
      updateProduct({ selectedAction, selectedEntity })
      updateDashboard({ showCreateComparisonDialog: true })
      break
    case MenuAction.DeleteProduct:
      updateProduct({ selectedAction, showDeleteProductDialog: true, selectedEntity })
      break
    default:
      break
    }
  }

  const items = [
    {
      action: MenuAction.MoveProductToFolder,
      label: t('actions.moveTo', { context: 'folder', ns: 'product' }),
      icon: <FontAwesomeIcon icon={faFolder} className="text-sm" />,
      command: executeCommand,
      template: MenuItemComponent
    },
    {
      action: isProduct ? MenuAction.MoveProductToObjects : MenuAction.MoveObjectToProduct,
      label: t('actions.moveTo', { context: isProduct ? ProductLabel.Object : ProductLabel.Product, ns: 'product' }),
      icon: <FontAwesomeIcon icon={isProduct ? faGrid2 : faTag} className={isProduct ? 'text-sm' : 'text-base'} />,
      command: executeCommand,
      template: MenuItemComponent
    },
    {
      action: MenuAction.CompareProducts,
      label: t('actions.compare', { context: label, ns: 'product' }),
      icon: <FontAwesomeIcon icon={faBringForward} rotation={90} className="text-sm" />,
      command: executeCommand,
      template: MenuItemComponent
    },
    {
      action: MenuAction.CreateScenario,
      label: t('actions.create', { context: 'scenario' }),
      icon: <FontAwesomeIcon icon={faFileCirclePlus} className="text-sm" />,
      command: executeCommand,
      template: MenuItemComponent
    },
    {
      action: MenuAction.DeleteProduct,
      label: t('actions.delete', { context: label, ns: 'product' }),
      icon: <FontAwesomeIcon icon={faTrash} className="text-sm" />,
      textColor: '--red-500',
      command: executeCommand,
      template: MenuItemComponent
    }
  ]

  const onMenuClick = (event: any) => {
    event.stopPropagation()
    menu.current?.toggle(event)
  }

  const onFavClick = (event: any) => {
    event.stopPropagation()
    changeProductFavoriteState({ variables: { productID: id, favorite: !favorite } })
  }

  const getFavIcon = () => {
    if (!favorite) {
      return <FontAwesomeIcon icon={faStar} className="text-base" />
    }

    return <FontAwesomeIcon icon={faSolidStar} className="text-base text-primary" />
  }

  const onSidebarHide = (event: any) => {
    const targetDOM = event.target as HTMLElement
    if (!targetDOM.dataset.productDetail && !targetDOM.parentElement?.dataset.productDetail) {
      updateProduct({ selectedEntity: null })
      closeSidebar()
    }
  }

  const openProductDetailSidebar = (productId: string) => {
    openSidebar(
      <ProductDetailPanelContainer afterChangeSidebarCallback={reloadProductList} productId={productId} />,
      onSidebarHide
    )
  }

  const onProductSidebar = (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    if (selectedEntity?.id === id) {
      updateProduct({ selectedEntity: null })
      closeSidebar()
      return
    }

    updateProduct({ selectedAction: MenuAction.OpenProductDetail })
    id && openProductDetailSidebar(id)
  }

  const onProductInfo = (event: any) => {
    event.stopPropagation()
    if (selectedEntity?.id === id) {
      updateProduct({ selectedEntity: null })
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      return
    }
    updateProduct({ selectedEntity: { id, name }, selectedAction: MenuAction.OpenProductDetail })
    store.dispatch(showProductInDetailsPanelSaga(id))
  }

  const isTableView = selectedViewType === ViewType.Tabular

  return (
    <>
      <CardMenuContainerWrapper className={classNames('flex flex-none justify-content-end', {
        'gap-3': isTableView,
        'gap-2': !isTableView
      })}
      >
        <Menu model={items} popup ref={menu} id="popup_menu" className="mt-1" />

        <Button
          visible={isMouseOver}
          icon={<FontAwesomeIcon icon={faEllipsis} className="text-lg" />}
          data-testid="card-menu-more"
          data-cy="card-menu-button"
          onClick={onMenuClick}
          className={classNames(
            'p-button-outlined p-button-plain p-button-sm p-0 border-none',
            { 'flex-order-2': isTableView }
          )}
          aria-controls="popup_menu"
          aria-haspopup
        />

        <Button
          icon={getFavIcon()}
          iconPos="left"
          loading={changingProductState}
          onClick={onFavClick}
          data-testid="card-menu-favorite"
          data-cy={`add-favorites-item-${normalize(name)}`}
          className={classNames('p-button-outlined p-button-plain p-button-sm p-0 border-none', {
            'flex-order-1': isTableView
          })}
          aria-controls="popup_menu"
          aria-haspopup
        />

        <Button
          onClick={enableProductDetailPanel ? onProductSidebar : onProductInfo}
          icon={<FontAwesomeIcon data-product-detail icon={faSidebarFlip} className="text-lg" />}
          data-testid="card-menu-info"
          data-cy="info-button"
          className={classNames('p-button-outlined p-button-plain p-button-sm p-0 border-none ', {
            'flex-order-0': isTableView,
            'text-primary-500': isSelected
          })}
          aria-controls="popup_menu"
          aria-haspopup
        />

      </CardMenuContainerWrapper>
    </>
  )
}
