import React, { useContext, useEffect } from 'react'
import { useReactiveVar, useMutation } from '@apollo/client'
import { Dropdown } from 'primereact/dropdown'
import styled, { createGlobalStyle } from 'styled-components'

import { useTranslation } from 'react-i18next'
import { CHANGE_SPACE_IMPACT_SETTINGS } from '../../../graphql/mutation'
import { impactCategoriesVar, selectedWorkspaceVar } from '../../../graphql/cache'
import { IImpactCategory } from '../../model'

import { TProductDetailContext } from '../interface/product-detail-context.type'
import ProductDetailContext from '../provider/context/product-detail.context'
import { TrackEventType } from '../../shared/enum/track-events'
import { getSegmentTrack } from '../../shared/util/segment'
import { useHandleMutationResponse } from '../../shared/hook/use-handle-mutation-response'

const ChangeImpactCategoryWrapper = styled.div`
  .p-dropdown {
    background: transparent;
    border: none !important;
    box-shadow: none !important;

    .p-dropdown-label {
      color: var(--gray-300);
      padding: 0 0.5rem;
      font-size: 16px;
    }

    .p-dropdown-trigger {
      color: var(--gray-300);
    }
  }
`

const GlobalStyle = createGlobalStyle`
  .change-impact-dropdown {
    .p-dropdown-items {
      padding: 1rem;
    }

    .p-dropdown-item {
      padding: 0.5rem !important;
    }
  }
`

export const ChangeImpactCategoryContainer = () => {
  const { t } = useTranslation([ 'common', 'product' ])
  const { handleResponse } = useHandleMutationResponse(t)

  const [ trackEventInSegment ] = getSegmentTrack()
  const selectedSpace = useReactiveVar(selectedWorkspaceVar) || {}
  const impactCategories = useReactiveVar(impactCategoriesVar) || []
  const { space = null } = selectedSpace
  const { refetchInventoryItems, updateProductDetail } = useContext<TProductDetailContext>(ProductDetailContext)
  const {
    id,
    excludeLT,
    useMethodTotal,
    nwSet,
    impactCategory,
    impactMethod
  } = space || {}
  const { id: nwSetID = null, type: nwSetType = null } = nwSet || {}
  const [
    changeSpaceImpactSettings,
    {
      error: failedChangingSpaceImpactSettings,
      data: { changeSpaceImpactSettings: changedSpaceImpactSettings = null } = {},
      loading: changingSpaceImpactSettings
    }
  ] = useMutation(CHANGE_SPACE_IMPACT_SETTINGS)

  useEffect(() => {
    if (!changingSpaceImpactSettings && (changedSpaceImpactSettings || failedChangingSpaceImpactSettings)) {
      handleResponse({
        error: failedChangingSpaceImpactSettings,
        data: changedSpaceImpactSettings,
        callback: changeSpaceImpactSettingsCallback,
        successToastDetail: { label: 'messages.success', context: 'changeImpactCategory', ns: 'product' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'changeImpactCategory', ns: 'product' }
      })
    }
  }, [ changingSpaceImpactSettings, changedSpaceImpactSettings, failedChangingSpaceImpactSettings ])

  const changeSpaceImpactSettingsCallback = () => {
    trackEventInSegment(TrackEventType.CHANGED_IMPACT_SETTINGS_CATEGORY)
    updateProductDetail({ hasInventoryChanged: true })
    refetchInventoryItems && refetchInventoryItems()
    selectedWorkspaceVar({
      ...selectedSpace,
      space: { ...selectedSpace.space, ...changedSpaceImpactSettings }
    })
  }

  const onChangeImpactSetting = ({ id: impactCategoryID }: IImpactCategory) => {
    const { id: impactMethodID } = impactMethod || {}
    const changeSpaceImpactSettingsArgs = {
      id,
      impactMethodID,
      impactCategoryID,
      nwSetID,
      nwSetType,
      excludeLT,
      useMethodTotal
    }
    changeSpaceImpactSettings({ variables: { ...changeSpaceImpactSettingsArgs } })
  }

  const impactOptionTemplate = (option: any) => (
    <div className="flex flex-none w-full text-sm line-height-1 text-gray-500">
      {' '}
      { option.name }
      {' '}
    </div>
  )

  return (
    <ChangeImpactCategoryWrapper>
      <GlobalStyle />
      <Dropdown
        data-testid="change-impact-dropdown"
        panelClassName="change-impact-dropdown"
        optionLabel="name"
        value={impactCategory}
        options={impactCategories}
        itemTemplate={impactOptionTemplate}
        tooltip={t('labels.impact', { context: 'category' })}
        tooltipOptions={{ position: 'bottom' }}
        onChange={(event: any) => onChangeImpactSetting(event.value)}
        placeholder="Select impact category"
      />
    </ChangeImpactCategoryWrapper>
  )
}
