import React, { useContext, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { TLifecycleContext } from '../interface/lifecycle-context.type'
import LifecycleContext from '../provider/context/lifecycle.context'
import { DeleteLifecycleComponent } from '../component/delete-lifecycle.component'
import { REMOVE_LIFECYCLE } from '../../graphql/mutation'
import { useHandleMutationResponse } from '../../shared/hook/use-handle-mutation-response'

type TDeleteLifecycleContainerProps = {
  afterDeleteLifecycle: () => void
}

export const DeleteLifecycleContainer = ({
  afterDeleteLifecycle
}: TDeleteLifecycleContainerProps) => {
  const { t } = useTranslation([ 'common' ])
  const { handleResponse } = useHandleMutationResponse(t)
  const { showDeleteLifecycleDialog = false, selectedLifecycle, updateLifecycle } = useContext<TLifecycleContext>(LifecycleContext)
  const [
    removeLifecycle,
    {
      error: failedRemovingLifecycle,
      data: removeLifecycleData,
      loading: removingLifecycle
    }
  ] = useMutation(REMOVE_LIFECYCLE)
  const lifecycleRemoved = removeLifecycleData?.removeLifecycle

  useEffect(() => {
    if (!removingLifecycle && (lifecycleRemoved || failedRemovingLifecycle)) {
      handleResponse({
        error: failedRemovingLifecycle,
        data: lifecycleRemoved,
        callback: removeLifecycleCallback,
        successToastDetail: { label: 'messages.success', context: 'removeLifecycle', ns: 'lifecycle' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'removeLifecycle', ns: 'lifecycle' }
      })
    }
  }, [ removingLifecycle, lifecycleRemoved, failedRemovingLifecycle ])

  const closeDeleteLifecycleDialog = () => {
    updateLifecycle({ showDeleteLifecycleDialog: false })
  }

  const handleDeleteLifecycle = () => {
    selectedLifecycle?.id && removeLifecycle({ variables: { id: selectedLifecycle?.id } })
  }

  const removeLifecycleCallback = () => {
    afterDeleteLifecycle()
    closeDeleteLifecycleDialog()
  }

  return (
    <>
      <DeleteLifecycleComponent
        handleDeleteLifecycle={handleDeleteLifecycle}
        closeDeleteLifecycleDialog={closeDeleteLifecycleDialog}
        showDeleteLifecycleDialog={showDeleteLifecycleDialog}
      />
    </>
  )
}
