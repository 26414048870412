import React, { useContext, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { TLifecycleDetailContext } from '../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../provider/context/lifecycle-detail.context'
import { REMOVE_PHASE } from '../../graphql/mutation'
import { DeletePhaseComponent } from '../component/delete-phase.component'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { useHandleMutationResponse } from '../../shared/hook/use-handle-mutation-response'

type TDeletePhaseContainerProps = {
  afterDeletePhase: () => void
}

export const DeletePhaseContainer = ({
  afterDeletePhase
}: TDeletePhaseContainerProps) => {
  const { t } = useTranslation([ 'common', 'lifecycle' ])
  const { handleResponse } = useHandleMutationResponse(t)

  const {
    showDeletePhaseDialog = false,
    selectedPhase,
    updateLifecycleDetail
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  const [
    removePhase,
    {
      error: failedRemoving,
      data: removedPhaseData,
      loading: removingPhase
    }
  ] = useMutation(REMOVE_PHASE)

  useEffect(() => {
    if (!removingPhase && (removedPhaseData?.removePhase || failedRemoving)) {
      handleResponse({
        error: failedRemoving,
        data: removedPhaseData,
        callback: removePhaseCallback,
        successToastDetail: { label: 'messages.success', context: 'removedPhase', ns: 'lifecycle' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'removedPhase', ns: 'lifecycle' }
      })
    }
  }, [ removingPhase, removedPhaseData, failedRemoving ])

  const [ trackEventInSegment ] = getSegmentTrack()

  const closeDeletePhaseDialog = () => {
    updateLifecycleDetail({ showDeletePhaseDialog: false, selectedPhase: null })
  }

  const handleDeletePhase = () => {
    selectedPhase?.id && removePhase({ variables: { id: selectedPhase?.id } })
  }

  const removePhaseCallback = () => {
    trackEventInSegment(TrackEventType.DELETED_PHASE)
    afterDeletePhase()
  }

  return (
    <>
      <DeletePhaseComponent
        handleDeletePhase={handleDeletePhase}
        closeDeletePhaseDialog={closeDeletePhaseDialog}
        showDeletePhaseDialog={showDeletePhaseDialog}
      />

    </>
  )
}
