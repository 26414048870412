export enum ScenarioAction {
  RemoveConversionItem = 'removeConversionItem',
  AddInventoryItem = 'addInventoryItem',
  CreateInventoryItem = 'createInventoryItem',
  ChangeInventoryItemAmount = 'changeInventoryItemAmount',
  ChangeProductReference = 'changeProductReference',
  ChangeProductCustomImpacts = 'changeProductCustomImpacts',
  RenameProduct = 'renameProduct',
  ChangeProductUnit = 'changeProductUnit',
  AddProductProperty = 'addProductProperty',
  ChangeProductProperty = 'changeProductProperty',
  RemoveProductReference = 'removeProductReference',
  RemoveProductProperty = 'removeProductProperty'
}
