import React, {
  useContext, useState, useEffect
} from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery, useMutation } from '@apollo/client'

import { POSSIBLE_INVENTORY_ITEMS } from '../../../graphql/query';
import { useHandleMutationResponse } from '../../../shared/hook/use-handle-mutation-response';
import { ADD_INVENTORY_ITEM, CREATE_INVENTORY_ITEM } from '../../../graphql/mutation';
import { TProductDetailContext } from '../../interface/product-detail-context.type';
import ProductDetailContext from '../../provider/context/product-detail.context';
import { TAnalysisContext } from '../../../analysis/interface/analysis.context';
import AnalysisContext from '../../../analysis/provider/context/analysis.context';
import { useScenarioAction } from '../../../analysis/hook/use-scenario-action';
import { AnalysisMode } from '../../../analysis/enum/analysis';
import { ScenarioAction } from '../../../analysis/enum/scenario-action';

type TAddSubItemFormContainerProps = {
  node?: any,
  onEscape?: () => void,
  ItemComponent: React.FC<any>
}
export const AddSubItemFormContainer = ({
  node = {},
  onEscape = () => {},
  ItemComponent
}: TAddSubItemFormContainerProps) => {
  const { t } = useTranslation([ 'product' ])
  const { handleResponse } = useHandleMutationResponse(t)
  const [ existingProducts, setExistingProducts ] = useState<any[]>([])
  const [ refetchingInventoryItems, setRefetchingInventoryItems ] = useState<boolean>(false)
  const {
    analysisMode,
    refetchInventory = () => {},
    refetchInventoryItems = () => {}
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const { scenarioProductID } = useContext<TAnalysisContext>(AnalysisContext)
  const handleScenarioAction = useScenarioAction(scenarioProductID)
  const { data: { parent = {}, ancestors = [] } = {} } = node
  const { product: { id: productID = null } = {}, amount } = parent

  const {
    loading: loadingPossibleInventoryItems,
    data: possibleInventoryItemsResponse,
  } = useQuery(POSSIBLE_INVENTORY_ITEMS, {
    skip: !productID,
    variables: {
      productID
    },
    fetchPolicy: 'no-cache'
  });

  const { possibleInventoryItems } = possibleInventoryItemsResponse || {}
  useEffect(() => {
    if (!loadingPossibleInventoryItems && possibleInventoryItems) {
      setExistingProducts(possibleInventoryItems)
    }
  }, [ loadingPossibleInventoryItems, possibleInventoryItems ]);

  const [
    createInventoryItem,
    {
      error: failedCreatingInventoryItem,
      data: createInventoryItemResponse = {},
      loading: creatingInventoryItem
    }
  ] = useMutation(CREATE_INVENTORY_ITEM)

  const createdInventoryItem = createInventoryItemResponse?.createInventoryItem
  useEffect(() => {
    if (!creatingInventoryItem && (createdInventoryItem || failedCreatingInventoryItem)) {
      handleResponse({
        error: failedCreatingInventoryItem,
        data: createdInventoryItem,
        callback: () => afterChangeCallback(),
        successToastDetail: { label: 'messages.success', context: 'createInventoryItem' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'createInventoryItem' }
      })
    }
  }, [ creatingInventoryItem, createdInventoryItem, failedCreatingInventoryItem ])

  const [
    addInventoryItem,
    {
      error: failedAddingInventoryItem,
      data: addInventoryItemResponse = {},
      loading: addingInventoryItem
    }
  ] = useMutation(ADD_INVENTORY_ITEM)

  const addedInventoryItem = addInventoryItemResponse?.addInventoryItem
  useEffect(() => {
    if (!addingInventoryItem && (addedInventoryItem || failedAddingInventoryItem)) {
      handleResponse({
        error: failedAddingInventoryItem,
        data: addedInventoryItem,
        callback: () => afterChangeCallback(),
        successToastDetail: { label: 'messages.success', context: 'addInventoryItem' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'addInventoryItem' }
      })
    }
  }, [ addingInventoryItem, addedInventoryItem, failedAddingInventoryItem ])

  const afterChangeCallback = () => {
    refetchInventory()
    refetchInventoryItems()
    setRefetchingInventoryItems(true)
  }

  const handleCreateSubItem = (data: any) => {
    const variables = {
      productID,
      productAmount: `${amount}`,
      name: data.name,
      value: data.amount,
      unit: data.unit,
      isWaste: false
    }

    if (analysisMode === AnalysisMode.Scenario) {
      handleScenarioAction
        && ancestors.length !== 0
        && handleScenarioAction(
          ScenarioAction.CreateInventoryItem,
          variables,
          ancestors[ancestors.length - 1].key,
          afterChangeCallback
        )
    } else {
      createInventoryItem({ variables })
    }
  }

  const handleAddSubItem = (inventoryItem: any) => {
    if (!inventoryItem?.id) return

    const variables = {
      productID,
      productAmount: `${amount}`,
      inventoryItemID: inventoryItem?.id,
      inventoryItemAmount: inventoryItem?.amount
    }

    if (analysisMode === AnalysisMode.Scenario) {
      handleScenarioAction
        && ancestors.length !== 0
        && handleScenarioAction(
          ScenarioAction.AddInventoryItem,
          variables,
          ancestors[ancestors.length - 1].key,
          afterChangeCallback
        )
    } else {
      addInventoryItem({ variables })
    }
  }

  return (
    <ItemComponent {...{
      refetchingInventoryItems,
      creatingInventoryItem,
      addingInventoryItem,
      handleAddSubItem,
      handleCreateSubItem,
      existingProducts,
      onEscape,
      node
    }}
    />
  )
}

