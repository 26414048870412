import React, {
  useState, useContext
} from 'react';
import styled from 'styled-components';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TTag } from '../../../../model';
import DashboardContext from '../../../../dashboard/context/dashboard.context';

interface TagViewModeProps {
  readonly color: string
}
const TagViewMode = styled.div<TagViewModeProps>`
  color: ${(props: TagViewModeProps) => props.color.toLowerCase()};
  background: ${(props: TagViewModeProps) => `${props.color.toLowerCase()}1a`};

  .tag-name {
    max-width: 10rem;
  }
`

type TTagViewModeComponentProps = {
  tag: TTag,
  onRemoveTag: () => void
}
export const TagViewModeItemComponent = ({
  tag, onRemoveTag
}: TTagViewModeComponentProps) => {
  const [ hoveredTag, setHoveredTag ] = useState<any>(null)
  const { editableSidebar = true } = useContext(DashboardContext);
  return (
    <>
      <TagViewMode
        data-testid="tag-view-mode"
        color={tag.color || ''}
        className="flex align-items-center border-round-sm px-1 gap-1"
        onMouseEnter={() => setHoveredTag(tag?.id)}
        onMouseLeave={() => setHoveredTag(null)}
      >
        <div className="flex-grow-1 text-sm white-space-nowrap overflow-hidden text-overflow-ellipsis tag-name">{tag?.name}</div>
        { hoveredTag === tag.id && editableSidebar && (
          <div className="flex align-items-center">
            <FontAwesomeIcon icon={faXmark} className="text-sm cursor-pointer" onClick={onRemoveTag} />
          </div>
        )}
      </TagViewMode>
    </>
  )
}
