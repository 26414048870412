import React, { useContext, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import ProductContext from '../../provider/context/product.context'
import { TProductContext } from '../../interface/product-context.type'
import { REMOVE_ITEM_FOLDER, REMOVE_PRODUCT } from '../../../graphql/mutation'

import { DeleteEntityComponent } from '../../component/dialog/delete-entity.component'
import { useHandleMutationResponse } from '../../../shared/hook/use-handle-mutation-response'

type TDeleteProductContainerProps = {
  afterDeleteProduct: () => void
}

export const DeleteEntityContainer = ({
  afterDeleteProduct
}: TDeleteProductContainerProps) => {
  const { t } = useTranslation([ 'common', 'product' ])
  const { handleResponse } = useHandleMutationResponse(t)

  const { showDeleteProductDialog = false, selectedEntity, updateProduct } = useContext<TProductContext>(ProductContext)
  const [
    removeProduct,
    {
      error: failedRemovingProduct,
      data: removeProductData,
      loading: removingProduct
    }
  ] = useMutation(REMOVE_PRODUCT)
  const productRemoved = removeProductData?.removeProduct

  useEffect(() => {
    if (!removingProduct && (productRemoved || failedRemovingProduct)) {
      handleResponse({
        error: failedRemovingProduct,
        data: productRemoved,
        callback: removeEntityCallback,
        successToastDetail: { label: 'messages.success', context: 'removeProduct', ns: 'product' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'removeProduct', ns: 'product' }
      })
    }
  }, [ removingProduct, productRemoved, failedRemovingProduct ])

  const [
    removeItemFolder,
    {
      error: failedRemovingItemFolder,
      data: removeItemFolderData,
      loading: removingItemFolder
    }
  ] = useMutation(REMOVE_ITEM_FOLDER)
  const removedItemFolder = removeItemFolderData?.removeItemFolder

  useEffect(() => {
    if (!removingItemFolder && (removedItemFolder || failedRemovingItemFolder)) {
      handleResponse({
        error: failedRemovingItemFolder,
        data: removedItemFolder,
        callback: removeEntityCallback,
        successToastDetail: { label: 'messages.success', context: 'removeFolder', ns: 'product' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'removeFolder', ns: 'product' }
      })
    }
  }, [ removingItemFolder, removedItemFolder, failedRemovingItemFolder ])

  const closeDeleteProductDialog = () => {
    updateProduct({ selectedEntity: null, showDeleteProductDialog: false })
  }

  const handleDeleteProduct = () => {
    const productID = selectedEntity?.id
    productID && removeProduct({ variables: { productID } })
  }

  const handleDeleteFolder = () => {
    const folderID = selectedEntity?.id
    folderID && removeItemFolder({ variables: { folderID } })
  }

  const removeEntityCallback = () => {
    afterDeleteProduct()
    closeDeleteProductDialog()
  }

  return (
    <>
      <DeleteEntityComponent
        removingProduct={removingProduct}
        handleDeleteProduct={handleDeleteProduct}
        handleDeleteFolder={handleDeleteFolder}
        closeDeleteProductDialog={closeDeleteProductDialog}
        showDeleteProductDialog={showDeleteProductDialog}
      />
    </>
  )
}
