import React, { useContext } from 'react'
import { Tooltip } from 'primereact/tooltip'
import styled from 'styled-components'
import { classNames } from 'primereact/utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag, faGrid2 } from '@fortawesome/pro-regular-svg-icons'

import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { ProductLabel } from '../../../shared/enum'
import { MenuToolsContainer } from '../../container/menu/menu-tools.container'
import { TooltipOverflowContent, useRefOverflow } from '../../../shared/hook/use-ref-overflow'
import { showProductInDetailsPanelSaga } from '../../../../redux/sagas/product.saga'
import { clearUiWhenDetailPanelCloseAction } from '../../../../redux/actions/clear.actions'
import { SelectedProductSelector } from '../../../../redux/selectors/product.selector'
import { store } from '../../../../configureStore'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { HeaderDetailBackComponent } from './header-detail-back.component'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { ProductDetailPanelContainer } from '../../container/detail-panel/product-detail-panel.container'
import FeatureContext from '../../../dashboard/context/feature.context'
import { Feature } from '../../../shared/enum/feature'
import { AnalysisMode } from '../../../analysis/enum/analysis'

const ProductNameBar = styled.div`
  .root-product-name {
    cursor: pointer;
    padding-right: 8rem !important; // Space for Get Help button

    &:hover {
      color: var(--primary-500) !important;
    }
  }

  .root-object-icon {
    color: var(--primarydark-400);
    background: var(--primarydark-50);
    border: 1px solid var(--primarydark-100);
  }
`

export const HeaderDetailComponent = () => {
  const [ productRef, hasProductOverflow, productDom ] = useRefOverflow()
  const {
    analysisMode,
    productInventory = {},
    totalImpact,
    updateProductDetail = () => {},
    refetchInventory = () => {}
  } = useContext<TProductDetailContext>(ProductDetailContext)

  const {
    openSidebar = () => {},
    closeSidebar = () => {}
  } = useContext(DashboardContext)

  const { isFeatureEnabled } = useContext(FeatureContext)
  const enableProductDetailPanel = isFeatureEnabled(Feature.ProductDetailPanel) || false

  const { product } = productInventory
  const [ productLabel ] = product?.labels || []
  const isProduct = productLabel?.id === ProductLabel.Product
  const openLegacyProductDetailSidebar = () => {
    // TODO : Added for compatibility with v0.9
    const state = store.getState()
    const selectedProductId = SelectedProductSelector.productId(state)
    const isDetailsPanelOpen = FlagsSelector.isDetailsPanelOpen(state)
    if (product?.id === selectedProductId && isDetailsPanelOpen) {
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      return
    }

    store.dispatch(showProductInDetailsPanelSaga(product?.id))
  }

  const onSidebarHide = (event: any, clearAlways: boolean = false) => {
    if (clearAlways) {
      closeProductDetailSidebar()
      return
    }

    const targetDOM = event.target as HTMLElement

    if (!targetDOM.dataset.treeItem) {
      closeProductDetailSidebar()
    }
  }

  const afterChangeRootProduct = () => {
    refetchInventory()
  }

  const openProductDetailSidebar = (productId: string) => {
    const selectedInventoryItem = { data: { inventoryItem: { amount: 1, impact: { ...totalImpact }, product } } };
    const isAnalysis = Object.values(AnalysisMode).some(mode => mode === analysisMode)
    openSidebar(
      <ProductDetailPanelContainer
        afterChangeSidebarCallback={afterChangeRootProduct}
        productId={productId}
        editableSidebar={!isAnalysis}
        selectedInventoryItem={selectedInventoryItem}
      />,
      onSidebarHide
    )
  }

  const openDetailPanel = () => {
    enableProductDetailPanel
      ? product?.id && openProductDetailSidebar(product.id)
      : openLegacyProductDetailSidebar()
  }

  const closeProductDetailSidebar = () => {
    updateProductDetail({
      selectedKey: null,
      selectedAction: null,
      selectedProduct: null,
      selectedInventoryKey: null,
      editAmountMode: false
    })
    closeSidebar()
  }

  const getTooltipContent = () => (
    <TooltipOverflowContent>
      {' '}
      {product?.name}
      {' '}
    </TooltipOverflowContent>
  )
  return (
    <>
      <div data-cy="go-back-button" className="flex align-items-center justify-content-start h-4rem">
        <HeaderDetailBackComponent />
      </div>
      <ProductNameBar className="flex align-items-center justify-content-start h-3rem">
        <Tooltip target={productDom} onBeforeShow={() => hasProductOverflow} position="bottom">{getTooltipContent()}</Tooltip>
        <div className={
          classNames('flex align-items-center justify-content-center border-1 border-round h-3rem w-3rem px-3', {
            'text-primary-500 bg-primary-50 border-primary-100': isProduct,
            'root-object-icon': !isProduct
          })
        }
        >
          <FontAwesomeIcon data-testid="header-detail-back-icon" icon={isProduct ? faTag : faGrid2} className="text-3xl" />
        </div>
        <div
          ref={productRef}
          onClick={openDetailPanel}
          data-cy="product-title"
          data-testid="header-detail-name"
          className="flex-grow-1 text-gray-800 text-3xl font-medium px-3 white-space-nowrap overflow-hidden text-overflow-ellipsis py-1 root-product-name"
        >
          {product?.name}
        </div>
        <div data-testid="header-detail-menu-tools" className="flex-none flex h-full w-6rem">
          <MenuToolsContainer />
        </div>
      </ProductNameBar>
    </>
  )
}
