import React, { useEffect, useState } from 'react'
import { RadioButton } from 'primereact/radiobutton'
import { classNames } from 'primereact/utils'
import styled from 'styled-components'

import {
  // faCircleInfo,
  faChevronDown,
  faChevronUp
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Facet, FacetValue, FilterDatasetType, FilterElementaryFlowType
} from '../../../../__generated__/graphql'

const CategoryItem = styled.li`
  &:hover {
    .p-radiobutton-box {
      border-color: var(--primary-500) !important;
    }
  }
`

export type TDatabaseCategoryGroupComponentProps = {
  category: Facet
  title: string
  onDatabaseCategoriesChange?: (categoryName: FilterDatasetType, updateValues: string[]) => void,
  onElementaryFlowCategoriesChange?:(categoryName: FilterElementaryFlowType, updateValues: string[]) => void,
  selectedValues: string[]
}
export const DatabaseCategoryGroupComponent = ({
  category,
  title,
  onDatabaseCategoriesChange,
  onElementaryFlowCategoriesChange,
  selectedValues,
}: TDatabaseCategoryGroupComponentProps) => {
  const [ showCategories, setShowCategories ] = useState<boolean>(true)

  useEffect(() => {
    if (selectedValues.length > 0 || category.name === FilterDatasetType.D1) setShowCategories(false)
  }, [ selectedValues ])

  const toggleCategories = () => {
    setShowCategories(!showCategories)
  }

  const onSelect = (value:string) => {
    if (onDatabaseCategoriesChange) onDatabaseCategoriesChange(category.name as FilterDatasetType, [ value ])
    if (onElementaryFlowCategoriesChange) onElementaryFlowCategoriesChange(category.name as FilterElementaryFlowType, [ value ])
    setShowCategories(false)
  }

  const isChecked = (itemName: string) => selectedValues.includes(itemName)
  const showCounts = selectedValues.length === 0
  const getLabel = (item: FacetValue) => {
    const isSelected = isChecked(item.name)
    const parts = item.name.split(':');
    const label = parts.length > 1 ? `${parts[0]}: ${parts.slice(1).join(':')}` : item.name;
    return (
      <div className="text-sm text-grey-500">
        <span data-cy="category-item-label">{label}</span>
        {(showCounts || isSelected) && <span className="text-xs ml-1">({item.count})</span>}
      </div>
    )
  }

  const someItemsSelected = selectedValues.length > 0
  const itemsToShow = category.values.filter(value => Number(value.count) > 0)
  const allItemsHaveZeroCount = category.values.every(value => value.count === 0)

  if (allItemsHaveZeroCount && !someItemsSelected) return null

  return (
    <div className="flex flex-column w-full py-3">
      {/*
      TODO : To be implemented with the filter section
      This is placeholder for the data
    */}
      <div className={classNames(
        'flex w-full align-items-center py-1 border-bottom-1 border-gray-100 gap-2',
        { 'text-gray-500': showCategories, 'text-primary-500': !showCategories }
      )}
      >
        {/* Uncomment this icon when we will have content for it */}
        {/* <div className="flex w-1rem align-items-center text-primary-500">
          <FontAwesomeIcon icon={faCircleInfo} className="text-sm" />
        </div> */}
        <div
          data-testid="parent-category-name"
          className={classNames(
            'flex flex-grow-1 align-items-center text-base font-medium text-gray-500 line-height-2 cursor-pointer',
            {
              'text-gray-500': selectedValues.length === 0,
              'text-primary-500': selectedValues.length > 0
            }
          )}
          onClick={toggleCategories}
        >
          { title }
          { selectedValues.length > 0 && <> (1) </>}
        </div>
        <div data-testid="toggle-show-categories" className="flex w-1rem align-items-center text-gray-500 cursor-pointer" onClick={toggleCategories}>
          <FontAwesomeIcon icon={showCategories ? faChevronDown : faChevronUp} className="text-md" />
        </div>
      </div>

      { showCategories && (
        <ul data-testid="category-filter-wrapper" className="w-full pt-2">
          { itemsToShow?.map((category, index) => (
            <CategoryItem
              data-testid={`category-item-${index}`}
              key={`category-item-${index}`}
              className="flex align-items-center gap-2 w-full py-1 cursor-pointer"
            >
              <RadioButton
                data-testid={`category-checkbox-${index}`}
                inputId={category.name}
                name="category"
                value={category}
                onChange={(event:any) => onSelect(event.value.name)}
                checked={selectedValues.length > 0 && selectedValues[0] === category.name}
              />
              <label
                htmlFor={category.name}
                className="line-height-2 text-sm text-gray-800 cursor-pointer"
              >
                { getLabel(category)}
              </label>
            </CategoryItem>
          )) }
        </ul>
      ) }
    </div>
  )
}
