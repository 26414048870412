import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useLocation,
  useParams,
  useNavigate,
  generatePath,
  matchPath,
  useSearchParams
} from 'react-router-dom'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { IProduct, IReferenceProduct } from '../../model'
import { Navigation } from '../../shared/enum'
import { getNavigationStorageItem, removeNavigationStorageItem, setNavigationStorageItem } from '../../shared/util/navigation'
import { ImpactDatasetViewMode, ImpactDatasetViewType } from '../enum/impact-dataset-view-type'
import { store } from '../../../configureStore'
import { showProductInDetailsPanelSaga } from '../../../redux/sagas/product.saga'
import { isValid } from '../../shared/util/tools'
import AnalysisContext from '../../analysis/provider/context/analysis.context'
import { AnalysisMode } from '../../analysis/enum/analysis'
import { setIsDetailPanelReadonlyV1Action } from '../../../redux/actions/flags.actions'
import { getBackLinkLabel, getInitialTab, trackNavigation } from '../util/dataset-navigate'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import FeatureContext from '../../dashboard/context/feature.context'
import { Feature } from '../../shared/enum/feature'

export const useDatasetNavigate = (selectedProduct?: IProduct, scenarioProductID?: string, scenarioSelectedKey?: string) => {
  const { t } = useTranslation('common')
  const {
    accountId,
    workspaceSlug,
    inventoryItemId,
  } = useParams()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const {
    selectedAnalysisMode,
    selectedEntity,
    addToBackToList,
    updateDashboard
  } = useContext(DashboardContext)
  const { updateImpactDataset } = useContext(ImpactDatasetContext)
  const { analysisMode } = useContext(AnalysisContext)
  const { isFeatureEnabled } = useContext(FeatureContext)
  const enableProductDetailPanel = isFeatureEnabled(Feature.ProductDetailPanel) || false

  const activeTab = searchParams.get('activeTab')
  const viewMode = searchParams.get('viewMode')
  const scenarioProductIDParam = searchParams.get('scenarioProductID')
  const scenarioSelectedKeyParam = searchParams.get('scenarioSelectedKey')

  const navigateBackToSource = () => {
    const { pathname: backLinkFromStorage } = getNavigationStorageItem(inventoryItemId!) || {}

    if (backLinkFromStorage && inventoryItemId) {
      removeNavigationStorageItem(inventoryItemId)
      navigate(backLinkFromStorage)
    } else {
      const productPageLink = generatePath(Navigation.ProductObjectInventory, {
        accountId: accountId!,
        workspaceSlug: workspaceSlug!,
        productId: inventoryItemId!
      })
      navigate(productPageLink)
    }

    if (selectedEntity?.id && !enableProductDetailPanel) {
      store.dispatch(setIsDetailPanelReadonlyV1Action(false))
      store.dispatch(showProductInDetailsPanelSaga(selectedEntity.id))
    }

    if (selectedEntity?.id && enableProductDetailPanel) {
      updateDashboard({ reopenSidebar: true })
    }
  }

  const isAnalysisScenario = () => {
    if (enableProductDetailPanel) {
      return selectedAnalysisMode === AnalysisMode.Scenario
    }

    return analysisMode === AnalysisMode.Scenario
  }

  const storeSelectedProduct = (selectedProduct: IProduct) => {
    setNavigationStorageItem(selectedProduct.id!, {
      pathname: pathname + search,
      data: {
        selectedProduct
      }
    })
    updateDashboard({ selectedEntity: selectedProduct })
  }

  const datasetNavigate = (
    viewType: ImpactDatasetViewType = ImpactDatasetViewType.DatabaseSearch,
    viewMode: ImpactDatasetViewMode = ImpactDatasetViewMode.Result,
    replace = true
  ) => {
    const matchPathRes = matchPath(Navigation.ImpactDataset, pathname)
    const isOnDatasetPage = isValid(matchPathRes)

    let activeTab = viewType
    if (isOnDatasetPage) {
      const commonParams = { activeTab, viewMode }
      if (scenarioProductIDParam && scenarioSelectedKeyParam) {
        setSearchParams({
          ...commonParams,
          scenarioProductID: scenarioProductIDParam,
          scenarioSelectedKey: scenarioSelectedKeyParam
        }, { replace })
      } else {
        setSearchParams({ ...commonParams }, { replace })
      }
    } else if (accountId && workspaceSlug && selectedProduct?.id) {
      activeTab = getInitialTab(selectedProduct)
      storeSelectedProduct(selectedProduct)
      addToBackToList?.(getBackLinkLabel(t, pathname))

      const impactDatasetPathname = generatePath(Navigation.ImpactDataset, {
        accountId,
        workspaceSlug,
        inventoryItemId: selectedProduct.id,
      })
      const search = isAnalysisScenario() && scenarioProductID && scenarioSelectedKey
        ? `?activeTab=${activeTab}&viewMode=${viewMode}&scenarioProductID=${scenarioProductID}&scenarioSelectedKey=${scenarioSelectedKey}`
        : `?activeTab=${activeTab}&viewMode=${viewMode}`
      navigate({ pathname: impactDatasetPathname, search })
    }

    trackNavigation(activeTab, isOnDatasetPage)
  }

  const navigateToDatasetDetail = ({
    viewType = ImpactDatasetViewType.DatabaseSearch,
    selectedReferenceProduct,
    replace = true
  } : {
    viewType?: ImpactDatasetViewType,
    selectedReferenceProduct: IReferenceProduct,
    replace?: boolean
  }) => {
    addToBackToList?.(getBackLinkLabel(t, pathname))
    updateImpactDataset({ selectedReferenceProduct })
    datasetNavigate(viewType, ImpactDatasetViewMode.Detail, replace)
  }

  return {
    inventoryItemId,
    activeTab,
    viewMode,
    datasetNavigate,
    navigateBackToSource,
    navigateToDatasetDetail
  }
}
