import React, { useContext, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { REMOVE_PRODUCT_REFERENCE } from '../../../graphql/mutation'
import { DeleteReferenceProductComponent } from '../../component/dialog/delete-reference-product.component'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { IProduct } from '../../../model'
import { useHandleMutationResponse } from '../../../shared/hook/use-handle-mutation-response'
import { AnalysisMode } from '../../../analysis/enum/analysis'
import { useScenarioAction } from '../../../analysis/hook/use-scenario-action'
import { ScenarioAction } from '../../../analysis/enum/scenario-action'

type DeleteReferenceProductContainerProps = {
  afterDeleteTreeItem: () => void
}

export const DeleteReferenceProductContainer = ({
  afterDeleteTreeItem
}: DeleteReferenceProductContainerProps) => {
  const { t } = useTranslation([ 'common', 'product' ])
  const { handleResponse } = useHandleMutationResponse(t)
  const {
    selectedEntity,
    scenarioProductID,
    selectedInventoryItem,
    selectedAnalysisMode,
    showDeleteReferenceProduct = false,
    updateDashboard,
    afterChangeSidebarCallback = () => {}
  } = useContext(DashboardContext);
  const handleScenarioAction = useScenarioAction(scenarioProductID)
  const { inventoryItem = {} } = selectedInventoryItem?.data || {}
  const { product, conversionID } = inventoryItem || {}
  const selectedProduct = selectedEntity as IProduct
  const [
    removeProductReference,
    {
      error: failedRemovingReferenceProduct,
      data: removeProductReferenceData,
      loading: removingReferenceProduct
    }
  ] = useMutation(REMOVE_PRODUCT_REFERENCE)
  const removedReferenceProduct = removeProductReferenceData?.removeProductReference

  useEffect(() => {
    if (!removingReferenceProduct && (!!removedReferenceProduct || failedRemovingReferenceProduct)) {
      handleResponse({
        error: failedRemovingReferenceProduct,
        data: removedReferenceProduct,
        callback: removeImpactProductCallback,
        successToastDetail: { label: 'messages.success', context: 'removeImpactProduct', ns: 'product' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'removeImpactProduct', ns: 'product' }
      })
    }
  }, [ removingReferenceProduct, !!removedReferenceProduct, failedRemovingReferenceProduct ])

  const closeDeleteDialog = () => {
    updateDashboard({ showDeleteReferenceProduct: false })
  }

  const handleDeleteReferenceProduct = () => {
    if (selectedAnalysisMode === AnalysisMode.Scenario) {
      const variables = { conversionID, productID: product?.id }
      handleScenarioAction && selectedInventoryItem?.key && conversionID && product?.id
        && handleScenarioAction(ScenarioAction.RemoveProductReference, variables, selectedInventoryItem.key, removeImpactProductCallback)
    } else {
      selectedProduct?.id && removeProductReference({ variables: { productID: selectedProduct.id } })
    }
  }

  const removeImpactProductCallback = () => {
    afterDeleteTreeItem();
    closeDeleteDialog();
    afterChangeSidebarCallback();
    updateDashboard({ selectedEntity: { ...selectedProduct, referenceProduct: null } })
  }

  return (
    <>
      <DeleteReferenceProductComponent
        removingInProgress={removingReferenceProduct}
        handleDelete={handleDeleteReferenceProduct}
        closeDialog={closeDeleteDialog}
        showDialog={showDeleteReferenceProduct}
      />
    </>
  )
}
