import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client'
import { PRODUCT_WITH_IMPACT } from '../../../graphql/query';
import { IDashboardContext } from '../../../shared/interface/workspace-context-type';
import DashboardContext from '../../../dashboard/context/dashboard.context';
import { IImpact, IProduct } from '../../../model';
import { ProductDetailPanelLayout } from '../../layout/product-detail-panel.layout';
import { ProgressSpinnerComponent } from '../../../shared/component/general/progress-spinner.component';
import { TInventoryTreeItem } from '../../../shared/util/transform';
import { AnalysisMode } from '../../../analysis/enum/analysis';

type TProductDetailPanelContainerProps = {
  productId: string,
  afterChangeSidebarCallback?: (param?: Object) => void,
  selectedInventoryItem?: TInventoryTreeItem | null,
  selectedAnalysisMode?: AnalysisMode | null
  scenarioProductID?: string | null,
  editableSidebar?: boolean
}
export const ProductDetailPanelContainer = ({
  productId,
  selectedInventoryItem,
  afterChangeSidebarCallback,
  selectedAnalysisMode,
  scenarioProductID,
  editableSidebar = true
}: TProductDetailPanelContainerProps) => {
  const { updateDashboard } = useContext<IDashboardContext>(DashboardContext);

  const {
    loading: loadingProductWithImpact,
    data: productWithImpactData,
    refetch: refetchProductWithImpact
  } = useQuery(PRODUCT_WITH_IMPACT, {
    variables: { id: productId },
    fetchPolicy: 'no-cache'
  });
  const { product = null, impact = null } = productWithImpactData?.productWithImpact || {}
  useEffect(() => {
    if (!loadingProductWithImpact && productWithImpactData) {
      updateDashboard({
        editableSidebar,
        scenarioProductID,
        selectedAnalysisMode,
        selectedInventoryItem,
        selectedEntity: product as IProduct,
        selectedEntityImpact: impact as IImpact,
        afterChangeSidebarCallback,
        refetchProductWithImpact
      })
    }

    return () => {
      updateDashboard({
        selectedEntity: undefined,
        afterChangeSidebarCallback: undefined,
        refetchProductWithImpact: undefined
      })
    }
  }, [ loadingProductWithImpact, productWithImpactData ]);

  return (
    <>
      { loadingProductWithImpact ? <ProgressSpinnerComponent size={2} /> : <ProductDetailPanelLayout /> }
    </>
  );
}
