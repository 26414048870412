import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { PERFORM_SCENARIO_ACTION } from '../../graphql/mutation'
import { ScenarioAction } from '../enum/scenario-action'
import { useHandleMutationResponse } from '../../shared/hook/use-handle-mutation-response'

export const useScenarioAction = (scenarioProductID?: string | null) => {
  const { t } = useTranslation([ 'common', 'product' ])
  const [ afterScenario, setAfterScenario ] = useState<{ callback?: Function }>({})
  const { handleResponse } = useHandleMutationResponse(t)

  const [
    performScenarioAction,
    {
      error: failedPerformingScenarioAction,
      data: performScenarioActionData,
      loading: performingScenarioAction
    }
  ] = useMutation(PERFORM_SCENARIO_ACTION)

  useEffect(() => {
    if (!performingScenarioAction && afterScenario?.callback
      && (failedPerformingScenarioAction || performScenarioActionData)) {
      handleResponse({
        error: failedPerformingScenarioAction,
        data: performScenarioActionData,
        callback: performScenarioActionCallback,
        successToastDetail: { label: 'messages.success', context: 'changeInventoryAmount', ns: 'product' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'changeInventoryAmount', ns: 'product' }
      })
    }
  }, [ failedPerformingScenarioAction, performScenarioActionData, performingScenarioAction ])

  if (!scenarioProductID) return

  const performScenarioActionCallback = () => {
    afterScenario?.callback && afterScenario.callback(failedPerformingScenarioAction, performScenarioActionData)
  }

  const handleScenarioAction = (
    action: ScenarioAction,
    argument: any,
    inventoryNodeID: string,
    callback: Function
  ) => {
    if (scenarioProductID) {
      setAfterScenario({ callback })
      performScenarioAction({
        variables: {
          action,
          args: JSON.stringify(argument),
          inventoryNodeID,
          productID: argument.productID,
          scenarioProductID
        }
      })
    }
  }

  return handleScenarioAction
}
