import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

import { IProductProperty } from '../../../model'

type DeleteProductPropertyComponentProps = {
  selectedProductProperty?: IProductProperty | null,
  removingProduct?: boolean,
  showDeleteProductPropertyDialog: boolean,
  closeDeleteProductPropertyDialog: () => void,
  handleDeleteProductProperty: () => void
}
export const DeleteProductPropertyComponent = ({
  selectedProductProperty,
  removingProduct = false,
  showDeleteProductPropertyDialog,
  closeDeleteProductPropertyDialog,
  handleDeleteProductProperty
}: DeleteProductPropertyComponentProps) => {
  const { t } = useTranslation([ 'product', 'common' ]);

  const onHide = () => {
    closeDeleteProductPropertyDialog();
  }

  return (
    <Dialog
      className="w-30rem"
      data-testid="delete-entity-dialog"
      header={t('actions.delete', { context: 'productProperty' })}
      visible={showDeleteProductPropertyDialog}
      draggable={false}
      dismissableMask
      onHide={onHide}
    >
      <div className="w-full pb-6">
        <div className="col-12 p-0" data-testid="delete-entity-message">
          { t('messages.warning', {
            context: 'removeProductProperty',
            name: selectedProductProperty?.referenceProperty?.name
          })}
        </div>

        <div className="col-12 pt-6 px-0 flex justify-content-end gap-2">
          <Button
            onClick={onHide}
            data-testid="cancel-delete-product-property"
            label={t('actions.cancel', { ns: 'common' })}
            iconPos="left"
            loading={false}
            className="p-button-outlined p-button-plain justify-content-end"
          />

          <Button
            onClick={handleDeleteProductProperty}
            data-testid="button-delete-product-property"
            data-cy="button-delete-product-property"
            label={t('form.label', { context: 'delete', ns: 'common' })}
            iconPos="left"
            loading={removingProduct}
            className="p-button-danger justify-content-end"
          />
        </div>
      </div>
    </Dialog>
  )
}
