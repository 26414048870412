import React, { useContext, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { COPY_LIFECYCLE } from '../../graphql/mutation'
import { TLifecycleContext } from '../interface/lifecycle-context.type'
import LifecycleContext from '../provider/context/lifecycle.context'
import { CopyLifecycleComponent } from '../component/copy-lifecycle.component'
import { TrackEventType } from '../../shared/enum/track-events'
import { useHandleMutationResponse } from '../../shared/hook/use-handle-mutation-response'

type TCopyLifecycleContainerProps = {
  afterCopyLifecycle: (param: string, param1: TrackEventType) => void
}

export const CopyLifecycleContainer = ({
  afterCopyLifecycle
}: TCopyLifecycleContainerProps) => {
  const { t } = useTranslation([ 'common', 'lifecycle' ])
  const { handleResponse } = useHandleMutationResponse(t)
  const { showCopyLifecycleDialog = false, selectedLifecycle, updateLifecycle } = useContext<TLifecycleContext>(LifecycleContext)
  const [
    copyLifecycle,
    {
      error: failedCopingLifecycle,
      data: copyLifecycleData,
      loading: copingLifecycle
    }
  ] = useMutation(COPY_LIFECYCLE)
  const lifecycleCopied = copyLifecycleData?.copyLifecycle

  useEffect(() => {
    if (!copingLifecycle && (lifecycleCopied || failedCopingLifecycle)) {
      handleResponse({
        error: failedCopingLifecycle,
        data: lifecycleCopied,
        callback: copyLifecycleCallback,
        successToastDetail: { label: 'messages.success', context: 'copyLifecycle', ns: 'lifecycle' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'copyLifecycle', ns: 'lifecycle' }
      })
    }
  }, [ copingLifecycle, lifecycleCopied, failedCopingLifecycle ])

  const closeCopyLifecycleDialog = () => {
    updateLifecycle({ showCopyLifecycleDialog: false })
  }

  const handleCopyLifecycle = () => {
    selectedLifecycle?.id && copyLifecycle({ variables: { lifecycleID: selectedLifecycle?.id } })
  }

  const copyLifecycleCallback = () => {
    lifecycleCopied?.id && afterCopyLifecycle(lifecycleCopied.id, TrackEventType.COPIED_LIFECYCLE)
    closeCopyLifecycleDialog()
  }

  return (
    <>
      <CopyLifecycleComponent
        handleCopyLifecycle={handleCopyLifecycle}
        closeCopyLifecycleDialog={closeCopyLifecycleDialog}
        showCopyLifecycleDialog={showCopyLifecycleDialog}
      />
    </>
  )
}
