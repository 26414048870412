import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'

import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { CHANGE_INVENTORY_ITEM_AMOUNT } from '../../../graphql/mutation'

import { IInventoryItem } from '../../../model'
import { TreeItemAmountComponent } from '../../component/tree/tree-item-amount.component'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { AnalysisMode } from '../../../analysis/enum/analysis'
import { ScenarioAction } from '../../../analysis/enum/scenario-action'
import { TLifecycleDetailContext } from '../../../lifecycle/interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../../lifecycle/provider/context/lifecycle-detail.context'
import { useScenarioAction } from '../../../analysis/hook/use-scenario-action'
import { TAnalysisContext } from '../../../analysis/interface/analysis.context'
import AnalysisContext from '../../../analysis/provider/context/analysis.context'
import { useHandleMutationResponse } from '../../../shared/hook/use-handle-mutation-response'

type TParentItem = {
  id?: string
  amount?: number
}

type TreeItemAmountContainerProps = {
  nodeKey: string
  mouseOverNode?: boolean
  inventoryItem?: IInventoryItem
  parentItem?: TParentItem
}
export const TreeItemAmountContainer = ({
  nodeKey, mouseOverNode, inventoryItem, parentItem
}: TreeItemAmountContainerProps) => {
  const { t } = useTranslation([ 'common', 'product' ])
  const { handleResponse } = useHandleMutationResponse(t)
  const [ amount, setAmount ] = useState(inventoryItem?.amount?.toString())
  const { refetchLifecycle } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const {
    refetchInventoryItems, updateProductDetail, analysisMode
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const { scenarioProductID } = useContext<TAnalysisContext>(AnalysisContext)
  const handleScenarioAction = useScenarioAction(scenarioProductID)
  const toast = useRef<Toast>(null)
  const [
    changeInventoryItemAmount,
    {
      error: failedChangingInventoryItemAmount,
      data: changeInventoryItemAmountData,
      loading: changingInventoryItemAmount
    }
  ] = useMutation(CHANGE_INVENTORY_ITEM_AMOUNT)
  const changedInventoryItemAmount = changeInventoryItemAmountData?.changeInventoryItemAmount

  useEffect(() => {
    if (!changingInventoryItemAmount && (changedInventoryItemAmount || failedChangingInventoryItemAmount)) {
      handleResponse({
        error: failedChangingInventoryItemAmount,
        data: changedInventoryItemAmount,
        callback: changeInventoryItemAmountCallback,
        successToastDetail: { label: 'messages.success', context: 'changeInventoryAmount', ns: 'product' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'changeInventoryAmount', ns: 'product' }
      })
    }
  }, [ changingInventoryItemAmount, changedInventoryItemAmount, failedChangingInventoryItemAmount ])

  useEffect(() => {
    if (inventoryItem?.amount?.toString() !== amount) {
      setAmount(inventoryItem?.amount?.toString())
    }
  }, [ inventoryItem ])

  const changeInventoryItemAmountCallback = () => {
    refetchLifecycle && refetchLifecycle()
    refetchInventoryItems && refetchInventoryItems()
    updateProductDetail({ hasInventoryChanged: true })
  }

  const onChangeAmount = (value: string) => {
    const inventoryItemID = inventoryItem?.product?.id
    const targetID = parentItem?.id
    const targetAmount = parentItem?.amount?.toString()
    if (!inventoryItemID || !targetID || !targetAmount) return

    const variables = {
      inventoryItemID,
      targetID,
      targetAmount,
      inventoryItemAmount: value.toString()
    }
    if (analysisMode === AnalysisMode.Scenario && scenarioProductID) {
      handleScenarioAction && handleScenarioAction(
        ScenarioAction.ChangeInventoryItemAmount,
        { ...variables, productID: inventoryItemID },
        nodeKey,
        changeInventoryItemAmountCallback
      )
    } else {
      changeInventoryItemAmount({ variables })
    }
  }

  return (
    <>
      { changingInventoryItemAmount && <Button iconPos="left" loading={changingInventoryItemAmount} className="p-button-outlined p-button-plain p-button-sm p-0 border-none" />}
      { !changingInventoryItemAmount && <TreeItemAmountComponent mouseOverNode={mouseOverNode} nodeKey={nodeKey} amount={amount} onChangeAmount={onChangeAmount} /> }

      <Toast data-testid="card-menu-status" ref={toast} position="top-right" />
    </>
  )
}
