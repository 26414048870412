import React, { useContext, useState, useEffect } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { classNames } from 'primereact/utils';
import { Tooltip } from 'primereact/tooltip';

import { faPlus, faTrash, faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IProduct, IProductProperty, IReferenceProperty } from '../../../model';

import { REFERENCE_PROPERTIES } from '../../../graphql/query';
import { AdditionalPropertyFormComponent } from '../../component/detail-panel/view-template/additional-property-form.component';
import { ADD_PRODUCT_PROPERTY, CHANGE_PRODUCT_PROPERTY, REMOVE_PRODUCT_PROPERTY } from '../../../graphql/mutation';
import DashboardContext from '../../../dashboard/context/dashboard.context';
import { useHandleMutationResponse } from '../../../shared/hook/use-handle-mutation-response';
import { DeleteProductPropertyComponent } from '../../component/dialog/delete-product-property.component';
import { useScenarioAction } from '../../../analysis/hook/use-scenario-action';
import { AnalysisMode } from '../../../analysis/enum/analysis';
import { ScenarioAction } from '../../../analysis/enum/scenario-action';

type TAdditionalPropertyComponentProps = {
  currentProperties: IProductProperty[]
}
export const AdditionalPropertyContainer = ({
  currentProperties = []
}: TAdditionalPropertyComponentProps) => {
  const { t } = useTranslation([ 'product', 'common' ])
  const { handleResponse } = useHandleMutationResponse(t)
  const {
    editableSidebar,
    scenarioProductID,
    selectedAnalysisMode,
    selectedInventoryItem,
    afterChangeSidebarCallback = () => {},
    selectedEntity = {},
    refetchProductWithImpact = () => {},
    updateDashboard
  } = useContext(DashboardContext)
  const [ enablePropertyEdit, setEnablePropertyEdit ] = useState<boolean>(false);
  const [ enablePropertyDelete, setEnablePropertyDelete ] = useState<boolean>(false);
  const [ propertiesOptions, setPropertiesOptions ] = useState<any[]>([])
  const [ selectedProductProperty, setSelectedProductProperty ] = useState<IProductProperty | null>(null)
  const handleScenarioAction = useScenarioAction(scenarioProductID)
  const { inventoryItem = {} } = selectedInventoryItem?.data || {}
  const { conversionID } = inventoryItem || {}
  const { hasInventory, referenceProduct } = selectedEntity as IProduct
  const isScenario = selectedAnalysisMode === AnalysisMode.Scenario
  const {
    loading: loadingReferenceProperties,
    data: referencePropertiesData,
  } = useQuery(REFERENCE_PROPERTIES, {
    fetchPolicy: 'no-cache'
  })

  const [
    addProductProperty,
    {
      error: failedAddingProductProperty,
      data: addProductPropertyData,
      loading: addingProductProperty
    }
  ] = useMutation(ADD_PRODUCT_PROPERTY)

  const [
    changeProductProperty,
    {
      error: failedChangingProductProperty,
      data: changeProductPropertyData,
      loading: changingProductProperty
    }
  ] = useMutation(CHANGE_PRODUCT_PROPERTY)

  const [
    removeProductProperty,
    {
      error: failedRemovingProductProperty,
      data: removeProductPropertyData,
      loading: removingProductProperty
    }
  ] = useMutation(REMOVE_PRODUCT_PROPERTY)

  const removedProductProperty = removeProductPropertyData?.removeProductProperty;
  useEffect(() => {
    if (!removingProductProperty && (!!removedProductProperty || failedRemovingProductProperty)) {
      handleProductPropertyCallback('removeProductProperty', removedProductProperty, failedRemovingProductProperty);
    }
  }, [ removingProductProperty, !!removedProductProperty, failedRemovingProductProperty ])

  const changedProductProperty = changeProductPropertyData?.changeProductProperty;
  useEffect(() => {
    if (!changingProductProperty && (!!changedProductProperty || failedChangingProductProperty)) {
      handleProductPropertyCallback('changeProductProperty', changedProductProperty, failedChangingProductProperty);
    }
  }, [ changingProductProperty, !!changedProductProperty, failedChangingProductProperty ])

  const addedProductProperty = addProductPropertyData?.addProductProperty;
  useEffect(() => {
    if (!addingProductProperty && (!!addedProductProperty || failedAddingProductProperty)) {
      handleProductPropertyCallback('addProductProperty', addedProductProperty, failedAddingProductProperty);
    }
  }, [ addingProductProperty, !!addedProductProperty, failedAddingProductProperty ])

  const { referenceProperties } = referencePropertiesData || {}
  useEffect(() => {
    if (!loadingReferenceProperties && referenceProperties) {
      setPropertiesOptions(
        referenceProperties
          .map((referenceProperty: IReferenceProperty) => ({ label: referenceProperty.name, value: referenceProperty.id }))
          .sort((a: any, b: any) => a.label.localeCompare(b.label))
      )
    }
  }, [ loadingReferenceProperties, referencePropertiesData ])

  const callback = () => {
    setEnablePropertyEdit(false);
    setEnablePropertyDelete(false);
    afterChangeSidebarCallback();
    !isScenario && refetchProductWithImpact();
  }

  const handleProductPropertyCallback = (context: string, data?: any, error?: any) => {
    handleResponse({
      data,
      error,
      callback,
      successToastDetail: { label: 'messages.success', context },
      errorToastSummary: { label: 'messages.errorSummary', context }
    })
  }

  const handleAddProductProperty = (data: any) => {
    if (!selectedEntity?.id
      || !data.referenceUnit
      || !data.conversionFactor
      || !data.referenceProperty
    ) return

    const existingProperty = currentProperties.find(property => property.referenceProperty?.id === data.referenceProperty)

    const common = {
      productID: selectedEntity.id,
      referencePropertyID: data.referenceProperty,
      conversionFactor: data.conversionFactor,
      referenceUnitID: data.referenceUnit
    }
    if (isScenario) {
      const variables = { conversionID, ...common }
      const scenarioAction = existingProperty ? ScenarioAction.ChangeProductProperty : ScenarioAction.AddProductProperty
      handleScenarioAction && selectedInventoryItem?.key && conversionID
        && handleScenarioAction(
          scenarioAction,
          variables,
          selectedInventoryItem.key,
          (error: any, data: any) => {
            data?.performScenarioAction && updateDashboard({ selectedEntity: data.performScenarioAction })
            handleProductPropertyCallback('addProductProperty', data?.performScenarioAction, error)
          }
        )
    } else {
      const handleProductProperty = existingProperty ? changeProductProperty : addProductProperty
      handleProductProperty({ variables: { ...common } })
    }
  }

  const handleRemoveProductProperty = () => {
    if (!selectedEntity?.id
      || !selectedProductProperty?.referenceProperty?.id
    ) return

    const common = {
      productID: selectedEntity.id,
      referencePropertyID: selectedProductProperty.referenceProperty.id,
    }
    if (isScenario) {
      const variables = { conversionID, ...common }
      handleScenarioAction && selectedInventoryItem?.key && conversionID
        && handleScenarioAction(
          ScenarioAction.RemoveProductProperty,
          variables,
          selectedInventoryItem.key,
          (error: any, data: any) => {
            data?.performScenarioAction && updateDashboard({ selectedEntity: data.performScenarioAction })
            handleProductPropertyCallback('removeProductProperty', data, error)
          }
        )
    } else {
      removeProductProperty({ variables: { ...common } })
    }
  }

  const triggerRemoveProductProperty = (event: any, productProperty: IProductProperty) => {
    event.stopPropagation();
    setEnablePropertyDelete(true);
    setSelectedProductProperty(productProperty);
  }

  const selectProductProperty = (productProperty: IProductProperty | null) => {
    setSelectedProductProperty(productProperty);
    setEnablePropertyEdit(true)
  }

  const enableDeleteProperty = (productProperty: IProductProperty) => (productProperty.referenceProperty?.id !== referenceProduct?.referenceProperty?.id) || hasInventory

  return (
    <div className="flex flex-column gap-1 pt-2 w-full">

      { enablePropertyEdit && editableSidebar && (
        <AdditionalPropertyFormComponent
          referenceProperties={referenceProperties}
          productProperty={selectedProductProperty}
          handleAddProductProperty={handleAddProductProperty}
          setEnablePropertyEdit={setEnablePropertyEdit}
          propertiesOptions={propertiesOptions}
        />
      )}

      { currentProperties?.length > 0 && !enablePropertyEdit && (
        <div className="flex flex-column flex-wrap w-full gap-1">
          {
            currentProperties.map((property: IProductProperty, index: number) => (
              <div
                className={classNames('flex w-full p-2 gap-3 border-round-md', { 'cursor-pointer hover:bg-gray-50': editableSidebar })}
                key={`tag-view-mode-item-${index}`}
                onClick={() => editableSidebar && selectProductProperty(property)}
              >
                <div className="flex flex-grow-1 align-items-center">
                  {property.referenceProperty?.name}, {property.conversionFactor} {property.referenceUnit?.name}
                </div>

                {editableSidebar && enableDeleteProperty(property) && (
                  <div className="flex flex-none align-items-center" onClick={(event: any) => triggerRemoveProductProperty(event, property)}>
                    <FontAwesomeIcon icon={faTrash} className="text-sm text-red-500 cursor-pointer" />
                  </div>
                )}

                {editableSidebar && !enableDeleteProperty(property) && (
                  <div className="flex flex-none align-items-center">
                    <Tooltip className="w-15rem" target=".info-disabled-property" position="left">
                      {t('labels.referenceProperty', { context: 'required' })}
                    </Tooltip>

                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="text-sm text-gray-300 hover:text-primary-500 cursor-pointer info-disabled-property"
                    />
                  </div>
                )}
              </div>
            ))
          }
        </div>
      )}

      { !enablePropertyEdit && editableSidebar && (
        <div data-testid="add-tag" className="flex align-items-center cursor-pointer px-2" onClick={() => selectProductProperty(null)}>
          <FontAwesomeIcon icon={faPlus} className="text-sm text-primary-500" />
        </div>
      )}

      <DeleteProductPropertyComponent
        selectedProductProperty={selectedProductProperty}
        removingProduct={removingProductProperty}
        showDeleteProductPropertyDialog={enablePropertyDelete}
        closeDeleteProductPropertyDialog={() => setEnablePropertyDelete(false)}
        handleDeleteProductProperty={handleRemoveProductProperty}
      />
    </div>
  )
}
