import React, { useContext, useEffect } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { selectedWorkspaceVar } from '../../../../graphql/cache'
import ProductContext from '../../provider/context/product.context'
import { TProductContext } from '../../interface/product-context.type'
import { CREATE_ITEM_FOLDER, RENAME_ITEM_FOLDER } from '../../../graphql/mutation'
import { CreateFolderComponent } from '../../component/dialog/create-folder.component'

import { ProductLabel } from '../../../shared/enum'
import { useHandleMutationResponse } from '../../../shared/hook/use-handle-mutation-response'

type TCreateFolderContainerProps = {
  afterCreateFolder: () => void
}

export const CreateFolderContainer = ({
  afterCreateFolder
}: TCreateFolderContainerProps) => {
  const { t } = useTranslation([ 'common', 'product' ])
  const { handleResponse } = useHandleMutationResponse(t)
  const { space: { id: spaceID = null } = {} } = useReactiveVar(selectedWorkspaceVar) || {}
  const {
    label: labels = ProductLabel.Product,
    showCreateFolderDialog = false,
    currentFolder = null,
    updateProduct,
    selectedEntity
  } = useContext<TProductContext>(ProductContext)
  const { id: parentID = '' } = currentFolder || {}

  const [
    createItemFolder,
    {
      error: failedCreatingFolder,
      data: createdFolderData = {},
      loading: folderCreating
    }
  ] = useMutation(CREATE_ITEM_FOLDER)

  const folderCreated = createdFolderData?.createItemFolder

  useEffect(() => {
    if (!folderCreating && (folderCreated || failedCreatingFolder)) {
      handleResponse({
        error: failedCreatingFolder,
        data: folderCreated,
        callback: createFolderCallback,
        successToastDetail: { label: 'messages.success', context: 'createFolder', ns: 'common' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'createFolder', ns: 'common' }
      })
    }
  }, [ folderCreating, folderCreated, failedCreatingFolder ])

  const [
    renameItemFolder,
    {
      error: failedRenamingItemFolder,
      data: renamedItemFolderData = {},
      loading: renamingItemFolder
    }
  ] = useMutation(RENAME_ITEM_FOLDER)
  const renamedItemFolder = renamedItemFolderData?.renameItemFolder

  useEffect(() => {
    if (!renamingItemFolder && (renamedItemFolder || failedRenamingItemFolder)) {
      handleResponse({
        error: failedCreatingFolder,
        data: folderCreated,
        callback: renameFolderCallback,
        successToastDetail: { label: 'messages.success', context: 'renameFolder', ns: 'product' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'renameFolder', ns: 'product' }
      })
    }
  }, [ renamingItemFolder, folderCreated, failedRenamingItemFolder ])

  const closeCreateFolderDialog = () => {
    updateProduct({ showCreateFolderDialog: false })
  }

  const handleCreateFolder = (name: string) => {
    spaceID && createItemFolder({
      variables: {
        name, labels, spaceID, parentID
      }
    })
  }

  const handleRenameFolder = (newName: string) => {
    const folderID = selectedEntity?.id
    folderID && renameItemFolder({ variables: { folderID, newName } })
  }

  const createFolderCallback = () => {
    afterCreateFolder()
    closeCreateFolderDialog()
  }

  const renameFolderCallback = () => {
    afterCreateFolder()
    closeCreateFolderDialog()
  }

  return (
    <>
      {showCreateFolderDialog && (
        <CreateFolderComponent
          folderCreating={folderCreating}
          handleRenameFolder={handleRenameFolder}
          handleCreateFolder={handleCreateFolder}
          closeCreateFolderDialog={closeCreateFolderDialog}
          showCreateFolderDialog={showCreateFolderDialog}
        />
      )}
    </>
  )
}
