import React, {
  useContext, useEffect, useState
} from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import ProductContext from '../../provider/context/product.context'
import { TProductContext } from '../../interface/product-context.type'
import { COPY_PRODUCT } from '../../../graphql/mutation'
import { CreateScenarioComponent } from '../../component/dialog/create-scenario.component'

import { PRODUCT_WITH_IMPACT } from '../../../graphql/query'
import { IProduct } from '../../../model'
import { getSegmentTrack } from '../../../shared/util/segment'
import { TrackEventType } from '../../../shared/enum/track-events'
import { useProductAnalysisNavigate } from '../../../analysis/hook/use-product-analysis-navigate'
import { AnalysisMode } from '../../../analysis/enum/analysis'
import { useHandleMutationResponse } from '../../../shared/hook/use-handle-mutation-response'

export const CreateScenarioContainer = () => {
  const { t } = useTranslation([ 'common' ])
  const { handleResponse } = useHandleMutationResponse(t)

  const { productAnalysisNavigate } = useProductAnalysisNavigate(AnalysisMode.Scenario)
  const [ productWithImpact, setProductWithImpact ] = useState<IProduct | null>(null)
  const {
    showCreateScenarioDialog = false,
    updateProduct,
    selectedEntity
  } = useContext<TProductContext>(ProductContext)

  const selectedEntityID: string = selectedEntity?.id || ''
  const {
    loading: loadingProductWithImpact,
    data: productWithImpactData
  } = useQuery(PRODUCT_WITH_IMPACT, {
    skip: !selectedEntityID || !showCreateScenarioDialog,
    variables: { id: selectedEntityID },
    fetchPolicy: 'no-cache'
  })
  const [ trackEventInSegment ] = getSegmentTrack()

  useEffect(() => {
    if (!loadingProductWithImpact && productWithImpactData) {
      const { product = null } = productWithImpactData?.productWithImpact || {}
      setProductWithImpact(product as IProduct)
    }
  }, [ loadingProductWithImpact, productWithImpactData ])
  const [
    copyProduct,
    {
      error: failedCopyingProduct,
      data: copyProductData,
      loading: copyingProduct
    }
  ] = useMutation(COPY_PRODUCT)
  const copiedProduct = copyProductData?.copyProduct

  useEffect(() => {
    if (!copyingProduct && (copiedProduct || failedCopyingProduct)) {
      handleResponse({
        error: failedCopyingProduct,
        data: copiedProduct,
        callback: createScenarioCallback,
        successToastDetail: { label: 'messages.success', context: 'createScenario' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'createScenario' }
      })
    }
  }, [ copyingProduct, copiedProduct, failedCopyingProduct ])

  const closeCreateScenarioDialog = () => {
    updateProduct({ selectedEntity: null, showCreateScenarioDialog: false })
  }

  const handleCreateScenario = () => {
    const productID = selectedEntity?.id
    productID && copyProduct({ variables: { productID } })
  }

  const createScenarioCallback = () => {
    trackEventInSegment(TrackEventType.CREATED_PRODUCT_SCENARIO)
    copiedProduct?.id && chooseScenario(copiedProduct.id)
    closeCreateScenarioDialog()
  }

  const chooseScenario = (scenarioId: string) => {
    trackEventInSegment(TrackEventType.OPENED_PRODUCT_SCENARIO)
    productAnalysisNavigate({
      selectedProductId: selectedEntity?.id,
      selectedSecondaryEntityId: scenarioId,
      backLabel: selectedEntity?.name
    })
  }

  return (
    <>
      { !loadingProductWithImpact && productWithImpact && (
        <CreateScenarioComponent
          copyingProduct={copyingProduct}
          handleChooseScenario={chooseScenario}
          productWithImpact={productWithImpact}
          handleCreateScenario={handleCreateScenario}
          closeCreateScenarioDialog={closeCreateScenarioDialog}
          showCreateScenarioDialog={showCreateScenarioDialog}
        />
      )}
    </>
  )
}
