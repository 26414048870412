
export enum ItemDetailComponent {
  Name = 'name',
  Unit = 'unit',
  Description = 'description',
  Module = 'module',
  Type = 'type',
  Tags = 'tags',
  AdditionalProperties = 'additionalProperties'
}
