import React, {
  useState, useRef, useContext, useEffect
} from 'react'

import { Tree } from 'primereact/tree'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretRight } from '@fortawesome/pro-solid-svg-icons'

import { TreeItemComponent } from './tree-item.component'
import { TInventoryTreeItem } from '../../../shared/util/transform'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { TreeHeaderComponent } from './tree-header.component'
import { store } from '../../../../configureStore'
import { showProductInDetailsPanelSaga } from '../../../../redux/sagas/product.saga'
import { clearUiWhenDetailPanelCloseAction } from '../../../../redux/actions/clear.actions'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { setIsCreateNewInventoryItemAction, setIsDetailPanelReadonlyV1Action } from '../../../../redux/actions/flags.actions'
import { AnalysisMode } from '../../../analysis/enum/analysis'
import { isValid } from '../../../shared/util/tools'
import { TreeImpactComponent } from './tree-impact.component'
import FeatureContext from '../../../dashboard/context/feature.context'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { Feature } from '../../../shared/enum/feature'
import { useIsMounted } from '../../../shared/hook/use-is-mounted'
import { ProductDetailPanelContainer } from '../../container/detail-panel/product-detail-panel.container'
import { AddSubItemFormContainer } from '../../container/model/tree-add-subitem-form.container'
import { AddSubItemFormComponent } from './tree-add-subitem-form.component'
import { TAnalysisContext } from '../../../analysis/interface/analysis.context'
import AnalysisContext from '../../../analysis/provider/context/analysis.context'

type TTreeWrapperProps = {
  theme?: any,
  analysisMode?: AnalysisMode
}

const TreeComponentWrapper = styled.div<TTreeWrapperProps>`
  width: 100%;

  .p-tree {
    width: 100%;
    border: none;
    background: none;
    padding: ${({ analysisMode }: TTreeWrapperProps) => (isValid(analysisMode) ? '0.5rem 1rem' : '0 2rem')};

    .p-treenode-droppoint {
      height: 0.25rem;
    }

    .p-treenode-content {
      padding: 0;
      background: var(${({ theme }: TTreeWrapperProps) => theme.inventoryTree.background});
      border-radius: 0.5rem !important;
      padding: 0.25rem !important;
      align-items: flex-start;

      .p-tree-toggler {
        width: 1.5rem !important;
        height: 1.5rem !important;
        margin: 0 0.25rem 0 0.5rem !important;
      }

      &:hover {
        background: var(${({ theme }: TTreeWrapperProps) => theme.inventoryTree.background}) !important;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
        cursor: pointer;
      }

      &:focus,
      .p-tree-toggler:focus {
        box-shadow: none !important;
      }

      &.p-highlight {
        border: 1px solid var(${({ theme }: TTreeWrapperProps) => theme.inventoryTree.highlightBorder});
      }
    }

    .p-tree-container, .p-tree-header {
      padding: 0 0.25rem;
    }

    .p-treenode-leaf > .p-treenode-content .p-tree-toggler {
      display: none;
    }

    .p-treenode-children .p-treenode-content  {
      background: var(--primarydark-50) !important;
    }

    .p-treenode {
      padding: 0.25rem 0;
    }

    .p-treenode-children {
      padding-top: 0.25rem !important;

      li.p-treenode:last-child {
        padding-bottom: 0;
      }
    }
  }

  &.notSelectable {
    .p-treenode-content {
      cursor: default !important;
      box-shadow: none !important;
    }
  }
`

export type TTreeComponentProps = {
  inventoryTree?: TInventoryTreeItem[],
  showImpact?: boolean,
}
export const TreeComponent = ({
  inventoryTree = [],
  showImpact
}: TTreeComponentProps) => {
  const { isFeatureEnabled } = useContext(FeatureContext)
  const enableProductDetailPanel = isFeatureEnabled(Feature.ProductDetailPanel) || false
  const enableTreeDnDPolarChartFixedSidebar = isFeatureEnabled(Feature.TreeDnDPolarChartFixedSidebar) || false
  const [ dndEnabledNodes, setDndEnabledNodes ] = useState<TInventoryTreeItem[]>([]);
  const [ createItemParentNode, setCreateItemParentNode ] = useState<any>(null)
  const toast = useRef<Toast>(null)
  const {
    analysisMode,
    productInventory,
    treeIsSecondary,
    selectedKey,
    expandedKeys = [],
    updateProductDetail,
    getCreateItemNode = () => {},
    setLifecycleSelectedKeyAndPhase = () => {},
    refetchInventory = () => {},
    refetchInventoryItems = () => {}
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const {
    primaryEntityId,
    scenarioProductID,
    secondaryInventoryItemsRefetch,
    secondaryUpdateDetail,
    primaryUpdateDetail,
  } = useContext<TAnalysisContext>(AnalysisContext)

  useEffect(() => {
    if (enableTreeDnDPolarChartFixedSidebar && inventoryTree.length > 0) {
      setDndEnabledNodes(inventoryTree)
    }
  }, [ enableTreeDnDPolarChartFixedSidebar, inventoryTree ])

  const {
    openSidebar = () => {},
    closeSidebar = () => {},
    reopenSidebar = false,
    selectedEntity,
    selectedInventoryItem
  } = useContext(DashboardContext)
  const isMounted = useIsMounted()
  const componentIsMounted = isMounted()

  useEffect(() => {
    if (componentIsMounted) {
      closeProductDetailSidebar()
    }
  }, [ componentIsMounted ])

  useEffect(() => {
    if (reopenSidebar && inventoryTree.length > 0) {
      reopenProductDetailSidebar();
    }
  }, [ reopenSidebar, inventoryTree ])

  // TODO : Added for compatibility with v0.9
  const state = store.getState()
  const isDetailsPanelOpen = FlagsSelector.isDetailsPanelOpen(state)
  useEffect(() => {
    if (!isDetailsPanelOpen && !enableProductDetailPanel) {
      updateProductDetail({
        selectedKey: null,
        selectedAction: null,
        selectedProduct: null,
        selectedInventoryKey: null,
        editAmountMode: false
      })
    }
  }, [ isDetailsPanelOpen ])

  const togglerTemplate = (node: any, options: any) => {
    const { containerClassName, onClick } = options
    return (
      <button data-testid={`toggler-button-${node.key}`} type="button" className={containerClassName} onClick={onClick}>
        { node.expanded ? <FontAwesomeIcon icon={faCaretDown} className="text-base" /> : <FontAwesomeIcon icon={faCaretRight} className="text-base" /> }
      </button>
    )
  }

  const getParentNodeItem = (ancestors: any[] = [], copiedInventoryTree: TInventoryTreeItem[] = []) => {
    let parentNodeItem: TInventoryTreeItem = {};

    ancestors.slice(1).forEach((ancestor: TInventoryTreeItem, index: number) => {
      parentNodeItem = index === 0
        ? copiedInventoryTree.find(item => item.key === ancestor.key) || {}
        : parentNodeItem.children?.find(item => item.key === ancestor.key) || {};
    });

    return parentNodeItem
  }

  const reopenProductDetailSidebar = () => {
    if (!selectedInventoryItem || !selectedEntity?.id || !enableProductDetailPanel) return

    const inventoryTreeCopy = [ ...inventoryTree ]
    const { key, data: { ancestors } } = selectedInventoryItem
    const parentNodeItem: TInventoryTreeItem = getParentNodeItem(ancestors, inventoryTreeCopy)
    const currentNodeItem = parentNodeItem.key ? parentNodeItem.children?.find(item => item.key === key) : null

    if (currentNodeItem) {
      const selectedInventoryKey = currentNodeItem?.key
      openProductDetailSidebar(selectedEntity.id, currentNodeItem)
      updateProductDetail({ selectedInventoryKey, selectedKey: selectedInventoryKey || null })
    }
  }

  const onAddSubItem = (node: any) => {
    cancelAddSubItem();
    if (!node) return

    const inventoryTreeCopy = [ ...inventoryTree ]
    const { key, data: { ancestors } } = node

    const createNodeItem = getCreateItemNode(node)
    const parentNodeItem: TInventoryTreeItem = getParentNodeItem(ancestors, inventoryTreeCopy)

    const currentNodeItem = parentNodeItem.key ? parentNodeItem.children?.find(item => item.key === key) : node
    currentNodeItem.expanded = true
    currentNodeItem.children?.splice(0, 0, createNodeItem)

    setCreateItemParentNode(currentNodeItem.children[0])
    updateProductDetail({ inventoryTree: inventoryTreeCopy })
  }

  const cancelAddSubItem = () => {
    if (!createItemParentNode) return

    const { key, data: { ancestors } } = createItemParentNode
    const inventoryTreeCopy = [ ...inventoryTree ]
    const parentNodeItem = getParentNodeItem(ancestors, inventoryTreeCopy)

    const createNodeIndex = parentNodeItem.children?.findIndex(item => item.key === key)

    if (createNodeIndex !== undefined && createNodeIndex >= 0) {
      parentNodeItem.children?.splice(createNodeIndex, 1)
      updateProductDetail({ inventoryTree: inventoryTreeCopy })
    }
  }

  const nodeTemplate = (node: any, options: any) => {
    const { onClick: onNodeClick } = options?.props || {}
    const { data: { type } } = node || {}

    if (type === 'CreateItem') {
      return <AddSubItemFormContainer node={node} onEscape={cancelAddSubItem} ItemComponent={AddSubItemFormComponent} />
    }

    return showImpact ? (
      <TreeImpactComponent node={node} />
    ) : (
      <TreeItemComponent node={node} selectedKey={selectedKey} onNodeClick={onNodeClick} onAddSubItem={onAddSubItem} />
    )
  }

  const headerTemplate = () => <TreeHeaderComponent />

  const onSelectionChange = (e: any) => {
    if (e.value === selectedKey || showImpact) {
      updateProductDetail({ selectedKey: null })
    } else {
      updateProductDetail({ selectedKey: e.value })
    }
  }

  const onExpand = (event: any) => {
    const { node } = event

    updateProductDetail({ expandedKeys: [ ...expandedKeys, node.key ] })
  }

  const onCollapse = (event: any) => {
    const { node } = event
    const updatedExpandedKeys = expandedKeys.filter((key: string) => key !== node.key)
    updateProductDetail({ expandedKeys: [ ...updatedExpandedKeys ] })
  }

  const closeProductDetailSidebar = () => {
    updateProductDetail({
      selectedKey: null,
      selectedAction: null,
      selectedProduct: null,
      selectedInventoryKey: null,
      editAmountMode: false
    })
    closeSidebar()
  }

  const onSidebarHide = (event: any, clearAlways: boolean = false) => {
    if (clearAlways) {
      closeProductDetailSidebar()
      return
    }

    const targetDOM = event.target as HTMLElement

    if (!targetDOM.dataset.treeItem) {
      closeProductDetailSidebar()
    }
  }

  const afterChangeTreeItem = () => {
    if (analysisMode === AnalysisMode.Scenario) {
      secondaryInventoryItemsRefetch && secondaryInventoryItemsRefetch()
      primaryUpdateDetail && primaryUpdateDetail({ hasInventoryChanged: true })
      secondaryUpdateDetail && secondaryUpdateDetail({ hasInventoryChanged: true })
      return
    }

    refetchInventory()
    refetchInventoryItems()
  }

  const openProductDetailSidebar = (productId: string, selectedInventoryItem?: TInventoryTreeItem | null) => {
    const isAnalysis = Object.values(AnalysisMode).some(mode => mode === analysisMode)
    const isScenario = analysisMode === AnalysisMode.Scenario
    const isPrimary = primaryEntityId === productInventory?.product?.id
    openSidebar(
      <ProductDetailPanelContainer
        afterChangeSidebarCallback={afterChangeTreeItem}
        productId={productId}
        selectedInventoryItem={selectedInventoryItem}
        selectedAnalysisMode={analysisMode}
        scenarioProductID={scenarioProductID}
        editableSidebar={!isAnalysis || (isScenario && !isPrimary)}
      />,
      onSidebarHide
    )
  }

  const openLegacyProductDetailSidebar = (node: any) => {
    const { data: { inventoryItem: { product } } } = node
    store.dispatch(setIsCreateNewInventoryItemAction(false))

    if (node.key === selectedKey) {
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      return
    }

    setLifecycleSelectedKeyAndPhase(node.key)
    const scenarioMode = analysisMode === AnalysisMode.Scenario
    store.dispatch(setIsDetailPanelReadonlyV1Action(scenarioMode && !treeIsSecondary))
    store.dispatch(showProductInDetailsPanelSaga(product.id))
  }

  const onNodeClick = (event: any) => {
    if (showImpact || event?.node?.data?.type === 'CreateItem') {
      return null
    }

    event.originalEvent.stopPropagation()
    cancelAddSubItem();

    const { node } = event
    const { key, data: { inventoryItem: { product } } } = node
    if (selectedKey === key) {
      closeProductDetailSidebar()
      return
    }

    updateProductDetail({
      selectedInventoryKey: key,
      editAmountMode: false,
      selectedAction: null
    })

    enableProductDetailPanel
      ? product?.id && openProductDetailSidebar(product.id, node)
      : openLegacyProductDetailSidebar(node)
  }

  const dndTreeFields = enableTreeDnDPolarChartFixedSidebar
    ? {
      dragdropScope: 'tree-component',
      onDragDrop: (event:any) => setDndEnabledNodes(event.value)
    } : {}

  return (
    <TreeComponentWrapper
      className={classNames({ notSelectable: showImpact })}
      data-testid="tree-component-wrapper"
      analysisMode={analysisMode}
    >
      <Toast ref={toast} />
      <Tree
        {...dndTreeFields}
        value={enableTreeDnDPolarChartFixedSidebar ? dndEnabledNodes : inventoryTree}
        header={headerTemplate}
        togglerTemplate={togglerTemplate}
        nodeTemplate={nodeTemplate}
        selectionMode="single"
        selectionKeys={selectedKey}
        onNodeClick={(event: any) => onNodeClick(event)}
        onExpand={(event: any) => onExpand(event)}
        onCollapse={(event: any) => onCollapse(event)}
        onSelectionChange={onSelectionChange}
      />
    </TreeComponentWrapper>
  )
}
