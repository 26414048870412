import React, {
  useState, useRef, useEffect
} from 'react'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPage } from '@fortawesome/pro-regular-svg-icons'

import styled, { createGlobalStyle } from 'styled-components';

import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button'
import { useForm, Controller } from 'react-hook-form'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import { IControllerRender } from '../../../shared/interface/react-form-hook'
import { ProgressSpinnerComponent } from '../../../shared/component/general/progress-spinner.component';
import { ProductLabel } from '../../../shared/enum';

const GlobalStyle = createGlobalStyle`
  .product-menu {
    display: fixed;
    width: 20rem;
    max-height: 15rem;
    overflow: auto;

    .p-submenu-header {
      font-size: 12px;
      font-weight: 400;
      color: var(--gray-300);
      padding: 0.5rem 1.5rem 0.25rem;
    }

    .p-submenu-item {
      font-size: 14px;
      color: var(--gray-500);
    }
  }
`

const SubItemFormWrapper = styled.div`
  input {
    width: 15.5rem;
    padding: 0 0.5rem;
    height: 1.5rem;
    border-radius: 0.25rem;
    border: 1px solid var(--primary-500);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ::-webkit-input-placeholder {
      color: var(--gray-300);
    }

    &.p-disabled {
      color: var(--gray-300);
      border: 1px solid var(--gray-300);
    }
  }

  .p-button-outlined {
    width: 1.5rem;
    height: 1.5rem;
    box-shadow: none !important;
  }

  .btn-menu-item {
    color: var(--gray-300);
    border-color: var(--gray-300);

    &:hover {
      color: var(--primary-500);
      border-color: var(--primary-500);
    }
  }

`

export type TAddSubItemFormComponentProps = {
  node?: any,
  existingProducts?: any[],
  onEscape?: () => void,
  handleCreateSubItem?: (data: any) => void,
  handleAddSubItem?: (data: any) => void,
  creatingInventoryItem?: boolean,
  addingInventoryItem?: boolean,
  refetchingInventoryItems?: boolean
}
export const AddSubItemFormComponent = ({
  node = {},
  existingProducts = [],
  onEscape = () => {},
  handleAddSubItem = () => {},
  handleCreateSubItem = () => {},
  creatingInventoryItem = false,
  addingInventoryItem = false,
  refetchingInventoryItems = false
}: TAddSubItemFormComponentProps) => {
  const { t } = useTranslation([ 'product', 'common' ])
  const [ menuIsPlacedAboveInput, setIsMenuPlacedAboveInput ] = useState<boolean>(false)
  const [ isUnitDisabled, setIsUnitDisabled ] = useState<boolean>(false)
  const [ isCreateMode, setIsCreateMode ] = useState<boolean>(false)
  const [ isNameActive, setIsNameActive ] = useState<boolean>(false)
  const [ selectedExistingItem, setSelectedExistingItem ] = useState<any>(null)
  const [ productMenuItems, setProductMenuItems ] = useState<{ label?: string, value?: any }[]>([])

  const formRef = useRef<HTMLFormElement>(null)
  const menuRef = useRef<Menu>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOnEverywhere() {
      onEscape()
    }

    window.addEventListener('click', handleClickOnEverywhere)

    return () => window.removeEventListener('click', handleClickOnEverywhere)
  }, [ node ])

  const defaultValues = { name: '', unit: 'Unit', amount: '1' }
  const {
    control, handleSubmit, formState: { errors = {} }, setValue
  } = useForm({ defaultValues })

  const onKeyUp = (e: any) => {
    if (e.key === 'Escape') onEscape()
  }

  const getProductMenuItems = (filterBy?: string): { label?: string, items?: any[] }[] => {
    const allElements = filterBy
      ? existingProducts.filter(existingProduct => existingProduct.name?.toLowerCase().includes(filterBy.toLowerCase()))
      : existingProducts

    if (allElements.length === 0) return ([ { label: 'No item found' } ])

    const menuItems = [];
    const products = allElements.filter(product => product.labels[0].id.toLowerCase() === ProductLabel.Product.toLowerCase())
    const objects = allElements.filter(object => object.labels[0].id.toLowerCase() === ProductLabel.Object.toLowerCase())

    if (products.length > 0) {
      menuItems.push({ label: 'Products', items: products.map(product => ({ label: product.name, value: product, command: chooseExistingItem })) })
    }
    if (objects.length > 0) {
      menuItems.push({ label: 'Objects', items: objects.map(object => ({ label: object.name, value: object, command: chooseExistingItem })) })
    }

    return menuItems
  }

  const chooseExistingItem = (existingProduct: any) => {
    const { item: { value = null } = {} } = existingProduct || {}

    setValue('name', value?.name);
    setValue('unit', value?.unit);
    setValue('amount', '1');

    setIsUnitDisabled(true);
    setIsCreateMode(false);
    setSelectedExistingItem(value);
  }

  const stopPropagation = (event: any) => event.stopPropagation()

  const setProductMenuItemPosition = (event: any) => {
    stopPropagation(event)
    if (!wrapperRef?.current) return

    const { offsetParent, offsetTop } = wrapperRef.current || {}
    const { top: wrapperTop } = wrapperRef.current.getBoundingClientRect() || {}

    const top = wrapperTop || offsetTop
    const { offsetHeight: offsetParentHeight } = offsetParent as any || {}

    if (!offsetParentHeight || !top) return

    setIsMenuPlacedAboveInput((offsetParentHeight - top - 320 <= 0))
  }

  const handleClickOnItemName = (event: any) => {
    event.stopPropagation()
    setIsNameActive(true)
    menuRef.current?.show(event)

    setProductMenuItems(getProductMenuItems())
  }

  const handleChangeOnItemName = (event: any, field: any) => {
    field.onChange(event)
    menuRef.current?.show(event)

    setIsCreateMode(true)
    setSelectedExistingItem(null)
    setIsUnitDisabled(false)

    setProductMenuItems(getProductMenuItems(event.target?.value))
  }

  const onSubmit = (data: any) => {
    if (isCreateMode) {
      handleCreateSubItem(data)
    } else {
      selectedExistingItem?.id && handleAddSubItem({ ...data, id: selectedExistingItem.id })
    }
  }

  return (
    <SubItemFormWrapper
      ref={wrapperRef}
      onClick={stopPropagation}
      data-cy="add-subitem-form"
      data-testid="add-subitem-form"
      className="flex gap-2 justify-content-end align-items-center relative w-full"
    >
      <GlobalStyle />
      <Menu
        popup
        ref={menuRef}
        id="popup_menu"
        model={productMenuItems}
        onClick={stopPropagation}
        onShow={setProductMenuItemPosition}
        className={classNames('product-menu z-2', {
          'product-menu__aboveInput': menuIsPlacedAboveInput,
          'product-menu__belowInput': !menuIsPlacedAboveInput
        })}
        // appendTo="self"
      />
      <div className="flex-none flex align-items-center justify-content-center pl-2 line-height">
        <div className="ml-2 tree-item-file-icon">
          <FontAwesomeIcon data-tree-item data-testid="tree-item-file-icon" icon={faPage} className="text-base" />
        </div>
      </div>
      <div className="flex-grow-1 align-items-center" onClick={stopPropagation}>
        {
          (creatingInventoryItem || addingInventoryItem || refetchingInventoryItems)
            ? (
              <div data-testid="progress-spinner" className="flex justify-content-start px-3">
                <div className="flex w-3rem justify-content-center"><ProgressSpinnerComponent size={1} /></div>
              </div>
            )
            : (
              <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                <div className="flex w-full gap-2">
                  <div className="flex flex-column">
                    <Controller
                      name="amount"
                      control={control}
                      rules={{ required: 'Amount is required' }}
                      render={({ field, fieldState }: IControllerRender) => (
                        <InputText
                          id="amount"
                          {...field}
                          autoComplete="off"
                          onClick={(event: any) => {
                            event.stopPropagation()
                            setIsNameActive(false)
                          }}
                          placeholder="Amount"
                          onKeyUp={onKeyUp}
                          className={classNames('text-sm w-5rem h-2rem text-gray-300 border-gray-300 focus:text-gray-700 focus:border-primary-500', { 'p-invalid': fieldState.error })}
                        />
                      )}
                    />
                    { errors?.amount && <small className="ml-2 p-error">{ errors?.amount?.message }</small> }
                  </div>
                  <div className="flex flex-column">
                    <Controller
                      name="unit"
                      control={control}
                      rules={{ required: 'Unit is required' }}
                      render={({ field, fieldState }: IControllerRender) => (
                        <InputText
                          id="unit"
                          {...field}
                          onClick={(event: any) => {
                            event.stopPropagation()
                            setIsNameActive(false)
                          }}
                          autoComplete="off"
                          placeholder="Unit"
                          onKeyUp={onKeyUp}
                          disabled={isUnitDisabled}
                          className={classNames('text-sm w-5rem h-2rem text-gray-300 border-gray-300 focus:text-gray-700 focus:border-primary-500', { 'p-invalid': fieldState.error, 'bg-gray-50': isUnitDisabled })}
                        />
                      )}
                    />
                    { errors?.unit && <small className="ml-2 p-error">{ errors?.unit?.message }</small> }
                  </div>
                  <div className="flex align-items-center">of</div>
                  <div className="flex flex-column">
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: t('messages.errorMessage', { context: 'itemName' }) || '' }}
                      render={({ field, fieldState }: IControllerRender) => (
                        <InputText
                          id="name"
                          {...field}
                          autoFocus
                          onKeyUp={onKeyUp}
                          onFocus={() => setIsNameActive(true)}
                          autoComplete="off"
                          placeholder={isNameActive ? 'Create new item or search' : t('labels.name', { ns: 'common' }) || ''}
                          onClick={handleClickOnItemName}
                          onChange={(event: any) => handleChangeOnItemName(event, field)}
                          className={classNames('text-sm w-20rem h-2rem text-gray-300 border-gray-300 focus:text-gray-700 focus:border-primary-500', { 'p-invalid': fieldState.error })}
                        />
                      )}
                    />
                    { errors?.name && <small className="ml-2 p-error">{ errors?.name?.message }</small> }
                  </div>
                  <div className="flex flex-none align-items-center">
                    <Button
                      icon="pi pi-times"
                      type="button"
                      onClick={onEscape}
                      data-testid="tree-item-amount-cancel"
                      tooltip={t('actions.cancel', { ns: 'common' })}
                      tooltipOptions={{ position: 'bottom' }}
                      className="p-button-outlined btn-menu-item"
                    />
                  </div>
                  <div className="flex flex-none align-items-center">
                    <Button
                      icon="pi pi-check"
                      type="submit"
                      data-testid="tree-item-amount-submit"
                      tooltip={t('actions.save', { ns: 'common' })}
                      tooltipOptions={{ position: 'bottom' }}
                      className="p-button-outlined btn-menu-item"
                    />
                  </div>
                </div>
              </form>
            )
        }
      </div>
    </SubItemFormWrapper>
  )
}

