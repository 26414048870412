import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Tooltip } from 'primereact/tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkLarge } from '@fortawesome/pro-regular-svg-icons'

import { TooltipOverflowContent } from '../../shared/hook/use-ref-overflow'
import { IReferenceProduct } from '../../model'

const LabelWrapper = styled.div`
  min-height: 34px;
  max-height: 54px;
  overflow: hidden;
  line-height: 1.125rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: normal;
  -webkit-line-clamp: 'none';
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`

type TCompareDatasetsItemComponentProps = {
  datasetItem: IReferenceProduct,
  removeDatasetItem: Function,
}

export const CompareDatasetsItemComponent = ({
  datasetItem,
  removeDatasetItem
}: TCompareDatasetsItemComponentProps) => {
  const [ isOverflowing, setIsOverflowing ] = useState(false)
  const labelRef = useRef<HTMLDivElement>(null)
  const { name } = datasetItem

  useEffect(() => {
    if (!labelRef.current) return

    const element = labelRef.current
    if (element.scrollHeight > element.clientHeight) {
      element.style.webkitLineClamp = '3'
      element.classList.remove('flex')
      setIsOverflowing(true)
    } else {
      element.classList.add('flex')
      setIsOverflowing(false)
    }
  }, [ name ])

  const getTooltipContent = () => (<TooltipOverflowContent> { name } </TooltipOverflowContent>)

  return (
    <>
      { labelRef.current && <Tooltip data-testid="dataset-item-tooltip" target={labelRef.current} onBeforeShow={() => isOverflowing} position="bottom">{getTooltipContent()}</Tooltip> }

      <FontAwesomeIcon data-testid="remove-dataset-item" onClick={() => removeDatasetItem(datasetItem)} icon={faXmarkLarge} className="text-base cursor-pointer pl-3 hover:text-primary-500" />

      <LabelWrapper ref={labelRef} className="text-sm text-gray-700 text-gray-500 pr-3 w-16rem align-items-center">
        { name }
      </LabelWrapper>
    </>
  )
}
