import React, { useContext } from 'react'
import { Sidebar } from 'primereact/sidebar'
import { createGlobalStyle } from 'styled-components'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../context/dashboard.context'
import FeatureContext from '../context/feature.context'
import { Feature } from '../../shared/enum/feature'

const GlobalStyle = createGlobalStyle`
  .p-sidebar-mask {
    z-index: 1100 !important;
  }
`

export const SidebarComponent = () => {
  const { openedSidebar, sidebarComponent: SidebarComponent = null } = useContext<IDashboardContext>(DashboardContext)

  const { isFeatureEnabled } = useContext(FeatureContext)
  const enableTreeDnDPolarChartFixedSidebar = isFeatureEnabled(Feature.TreeDnDPolarChartFixedSidebar) || false

  if (openedSidebar && enableTreeDnDPolarChartFixedSidebar) {
    return (
      <>
        { SidebarComponent }
      </>
    )
  }

  return (
    <>
      <GlobalStyle />
      <Sidebar
        className="w-21rem"
        data-testid="dashboard-sidebar-component"
        visible={openedSidebar && !enableTreeDnDPolarChartFixedSidebar}
        position="right"
        modal={false}
        showCloseIcon={false}
        onHide={() => {}}
      >
        { SidebarComponent }
      </Sidebar>
    </>
  )
}
