import React from 'react';
import { Checkbox } from 'primereact/checkbox'
import { FacetValue } from '../../../../__generated__/graphql'

type TGeographyFilterListComponentProps = {
  itemList?: FacetValue[]
  selectedItemList?: string[]
  onChange?: Function
}
export const GeographyFilterListComponent = ({
  itemList = [],
  selectedItemList = [],
  onChange = () => {}
}:TGeographyFilterListComponentProps) => {
  const showCounts = selectedItemList.length === 0
  const getLocationRegionLabel = (item: FacetValue) => {
    const isSelected = selectedItemList.includes(item.name) && selectedItemList.includes(item.name)
    return (
      <div className="flex align-items-center text-sm text-gray-500">
        <span>{item.name}</span>
        {(showCounts || isSelected) && <span className="text-xs ml-1">({item.count})</span>}
      </div>
    )
  }
  return (
    <ul className="w-full pt-1 max-h-19rem overflow-y-auto" data-cy="geography-filter-dropdown" data-testid="geography-filter-list">
      { itemList.map((item, index) => (
        <li
          data-testid={`property-item-${index}`}
          key={`property-item-${index}`}
          className="flex align-items-center gap-2 w-full py-1"
        >
          <Checkbox
            inputId={item.name}
            name="property"
            value={item}
            onChange={(event:any) => onChange(event)}
            data-testid={`property-checkbox-${index}`}
            checked={selectedItemList.includes(item.name)}
            data-cy={`location-checkbox-${index}`}
          />
          <label
            htmlFor={item.name}
            className="line-height-2 text-sm text-gray-500"
            onClick={event => {
              event.stopPropagation()
              event.preventDefault()
            }}
          >
            {getLocationRegionLabel(item)}
          </label>
        </li>
      ))}
    </ul>
  )
}
