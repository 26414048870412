import React, { useContext } from 'react';

import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DashboardContext from '../../../dashboard/context/dashboard.context';
import { IDashboardContext } from '../../interface/workspace-context-type';

type TDetailPanelHeaderComponentProps = {
  afterCloseDetailPanel?: Function
}
export const DetailPanelHeaderComponent = ({
  afterCloseDetailPanel = () => {}
}: TDetailPanelHeaderComponentProps) => {
  const { closeSidebar = () => {} } = useContext<IDashboardContext>(DashboardContext);

  const onClose = () => {
    closeSidebar();
    afterCloseDetailPanel();
  }

  return (
    <div className="flex justify-content-end w-full h-full bg-primary-50 gap-3">
      <div className="flex align-items-center px-4 py-3">
        <FontAwesomeIcon
          icon={faXmark}
          onClick={onClose}
          data-testid="clear-search-fields"
          className="text-md text-gray-500 cursor-pointer hover:text-primary-500"
        />
      </div>
    </div>
  );
}
