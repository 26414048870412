import { gql } from '../../../__generated__'

export const CREATE_PRODUCT = gql(`
  mutation createProduct($name: String!, $spaceID: ID!, $folderID: ID, $labels: [ID!]!, $unit: String) {
    createProduct(name: $name, spaceID: $spaceID, labels: $labels, folderID: $folderID, unit: $unit) {
      ...extendedProductFragment
    }
  }
`)

export const REPLACE_LABEL = gql(`
  mutation replaceLabel($productID: ID!, $labelToReplace: ID!, $replacementLabel: ID!) {
    replaceLabel(productID: $productID, labelToReplace: $labelToReplace, replacementLabel: $replacementLabel) {
      id
      name
    }
  }
`)

export const CHANGE_PRODUCT_PRIVATE_STATE = gql(`
  mutation changeProductFavoriteState($productID: ID!, $favorite: Boolean!) {
    changeProductFavoriteState(productID: $productID, favorite: $favorite){
      ...extendedProductFragment
    }
  }
`)

export const COPY_PRODUCT = gql(`
  mutation copyProduct($productID: ID!) {
    copyProduct(productID: $productID) {
      ...extendedProductFragment
    }
  }
`)

export const REMOVE_PRODUCT = gql(`
  mutation removeProduct($productID: ID!) {
    removeProduct(productID: $productID)
  }
`)

export const REQUEST_PRODUCT_EXPORT = gql(`
  mutation requestProductExport($productID: ID!, $timezone: String!) {
    requestProductExport(productID: $productID, timezone: $timezone)
  }
`)

export const IMPORT_PRODUCT_TEMPLATE = gql(`
  mutation importProductTemplate($spaceID: ID!, $productTemplate: ProductTemplate!) {
    importProductTemplate(spaceID: $spaceID, productTemplate: $productTemplate) {
      id
      name
    }
  }
`)

export const ADD_PRODUCT_PROPERTY = gql(`
  mutation addProductProperty($productID: ID!, $referencePropertyID: ID!, $conversionFactor: String!, $referenceUnitID: ID!) {
    addProductProperty(productID: $productID, referencePropertyID: $referencePropertyID, conversionFactor: $conversionFactor, referenceUnitID: $referenceUnitID){
      id
      name
    }
  }
`)

export const CHANGE_PRODUCT_PROPERTY = gql(`
  mutation changeProductProperty($productID: ID!, $referencePropertyID: ID!, $conversionFactor: String!, $referenceUnitID: ID!) {
    changeProductProperty(productID: $productID, referencePropertyID: $referencePropertyID, conversionFactor: $conversionFactor, referenceUnitID: $referenceUnitID){
      id
      name
    }
  }
`)

export const REMOVE_PRODUCT_PROPERTY = gql(`
  mutation removeProductProperty($productID: ID!, $referencePropertyID: ID!) {
    removeProductProperty(productID: $productID, referencePropertyID: $referencePropertyID){
      id
      name
    }
  }
`)

export const RENAME_PRODUCT = gql(`
  mutation renameProduct($productID: ID!, $name: String!) {
    renameProduct(productID: $productID, name: $name) {
      id
      name
    }
  }
`)

export const CHANGE_PRODUCT_UNIT = gql(`
  mutation changeProductUnit($productID: ID!, $unit: String!) {
    changeProductUnit(productID: $productID, unit: $unit){
      id
      unit
    }
  }
`)

export const CHANGE_PRODUCT_DESCRIPTION = gql(`
  mutation changeProductDescription($productID: ID!, $description: String!) {
    changeProductDescription(productID: $productID, description: $description){
      id
      description
    }
  }
`)

export const CHANGE_PRODUCT_TYPE = gql(`
  mutation changeProductType($productID: ID!, $type: ProductType!) {
    changeProductType(productID: $productID, type: $type){
      id
      type
    }
  }
`)

export const ASSIGN_MODULE_TO_PRODUCT = gql(`
  mutation assignModuleToProduct($productID: ID!, $module: String!) {
    assignModuleToProduct(productID: $productID, module: $module){
      id
      module {
        code
      }
    }
  }
`)

export const CREATE_TAG = gql(`
  mutation createTag($name: String!, $color: String!, $spaceID: ID!, $productID:ID) {
    createTag(name: $name, color: $color, spaceID: $spaceID, productID: $productID) {
       id
       name
       color
    }
  }
`)

export const ADD_TAG_TO_PRODUCT = gql(`
  mutation addTagToProduct($tagID: ID!, $productID:ID!) {
    addTagToProduct(tagID: $tagID, productID: $productID) {
       tags {
         id
         name
         color
       }
    }
  }
`)

export const REMOVE_TAG_FROM_PRODUCT = gql(`
  mutation removeTagFromProduct($tagID: ID!, $productID:ID!) {
    removeTagFromProduct(tagID: $tagID, productID: $productID) {
      id
    }
  }
`)
